import { itemsSelectorFamily, useAppStore } from '../state/store';
import { createWithEqualityFn } from 'zustand/traditional';
import { CustomizerElement } from '../components/interfaces';
import { Map } from 'immutable';
import { shallow } from 'zustand/shallow';
import { useAtomCallback } from 'jotai/utils';
import { useCallback } from 'react';

type UndoRedoState = {
	items: CustomizerElement[];
	sidesRotations: Map<number, number>;
	sideAreaBackgrounds: Map<number, Map<number, { fillColor: string | null; imageItemGuid: string | null }>>;
	sidesCollages: Map<number, number | null>;
};

type UndoRedoPushStateOptions = {
	resetUndoRedo?: boolean;
	replace?: boolean;
};
type UndoRedoStore = {
	undoStack: UndoRedoState[];
	setUndoStack: (undoStack: UndoRedoState[]) => void;
	pushState: (state: UndoRedoState, options?: UndoRedoPushStateOptions) => void; // Useful for memoized functions where the undoStack is stale

	redoStack: UndoRedoState[];
	setRedoStack: (redoStack: UndoRedoState[]) => void;
};

const isEqualUndoRedoState = (state1: UndoRedoState, state2: UndoRedoState) => {
	if (state1.sidesCollages !== state2.sidesCollages) return false;
	if (state1.sidesRotations !== state2.sidesRotations) return false;
	if (state1.sideAreaBackgrounds !== state2.sideAreaBackgrounds) return false;
	if (state1.items === state2.items) return true;
	if (state1.items.length !== state2.items.length) return false;
	return state1.items.every((item, index) => item === state2.items[index]);
};
export const useUndoRedoStore = createWithEqualityFn<UndoRedoStore>(
	(set, get) => ({
		undoStack: [],
		redoStack: [],
		setUndoStack: (undoStack) => set({ undoStack }),
		setRedoStack: (redoStack) => set({ redoStack }),
		pushState: (state, options) => {
			if (options?.resetUndoRedo) {
				set({
					undoStack: [state],
					redoStack: [],
				});
			}
			// if the state is a know state (is equal to one of the known states)
			// then don't push it as the user haven't done any actions.
			if ([...get().undoStack, ...get().redoStack].some((prev) => isEqualUndoRedoState(state, prev))) return;
			if (options?.replace) {
				set({
					undoStack: [...get().undoStack.slice(0, get().undoStack.length - 1), state],
					redoStack: [],
				});
				return;
			}
			set({
				undoStack: [...get().undoStack, state],
				redoStack: [],
			});
		},
	}),
	shallow,
);

type RestoreUndoRedoState = (state: UndoRedoState) => void;
const useRestoreUndoRedoState = (): RestoreUndoRedoState => {
	return useAtomCallback(
		useCallback((_, set, state: UndoRedoState) => {
			set(itemsSelectorFamily, state.items);
			useAppStore.setState(
				(x) => ({
					...x,
					sidesRotations: state.sidesRotations,
					sideAreaBackgrounds: state.sideAreaBackgrounds,
					sidesCollages: state.sidesCollages,
					preventAdaptForSideRotation: true,
				}),
				true,
			);
		}, []),
	);
};

export const useDumpCurrentChanges = () => {
	const restoreState = useRestoreUndoRedoState();

	return useCallback(() => {
		const { undoStack } = useUndoRedoStore.getState();
		const newUndoStack = [...undoStack];
		const current = newUndoStack.pop();

		if (current) {
			console.log('Dumping current changes...');

			restoreState(current);
		}
	}, [restoreState]);
};

type UndoRedoCommitOptions = {
	resetUndoRedo?: boolean;
	replace?: boolean;
};
type UndoRedoCommit = (options?: UndoRedoCommitOptions) => void;
export const useUndoRedoCommit = (): UndoRedoCommit => {
	const pushState = useUndoRedoStore((x) => x.pushState);
	return useAtomCallback(
		useCallback(
			(get, _, options?: UndoRedoCommitOptions) => {
				pushState(
					{
						items: get(itemsSelectorFamily),
						sidesRotations: useAppStore.getState().sidesRotations,
						sideAreaBackgrounds: useAppStore.getState().sideAreaBackgrounds,
						sidesCollages: useAppStore.getState().sidesCollages,
					},
					options,
				);
			},
			[pushState],
		),
	);
};

const useUndoRedo = () => {
	const [setUndoStack, setRedoStack] = useUndoRedoStore((x) => [x.setUndoStack, x.setRedoStack]);

	const restoreUndoRedoState = useRestoreUndoRedoState();
	const commitState = useUndoRedoCommit();

	const resetUndoRedo = useCallback(() => {
		commitState({ resetUndoRedo: true });
	}, [commitState]);

	const undo = useCallback(() => {
		const { undoStack, redoStack } = useUndoRedoStore.getState();
		const newUndoStack = [...undoStack];
		const current = newUndoStack.pop();

		if (current && newUndoStack.length > 0) {
			console.log('Executing undo...');

			const newRedoStack = [...redoStack, current];

			const prev = newUndoStack[newUndoStack.length - 1];
			restoreUndoRedoState(prev);

			setUndoStack(newUndoStack);
			setRedoStack(newRedoStack);
		} else {
			console.log('No undo steps available.');
		}
	}, [restoreUndoRedoState, setRedoStack, setUndoStack]);

	const redo = useCallback(() => {
		const { undoStack, redoStack } = useUndoRedoStore.getState();
		const newRedoStack = [...redoStack];
		const current = newRedoStack.pop();

		if (current) {
			console.log('Executing redo...');

			const newUndoStack = [...undoStack, current];

			restoreUndoRedoState(current);

			setUndoStack(newUndoStack);
			setRedoStack(newRedoStack);
		} else {
			console.log('No redo steps available.');
		}
	}, [restoreUndoRedoState, setRedoStack, setUndoStack]);

	return {
		undo,
		redo,
		resetUndoRedo,
	};
};

export default useUndoRedo;
