import { ProductVariant, useZakekeProduct, useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import styled from 'styled-components';

import useVariantChanger from '../../hooks/useVariantChanger';
import { isMobile } from '../../shared/helpers';
import { Dialog, DialogWindow, useDialogManager } from '../widgets/dialogs';
import EmptyMessage from '../widgets/emptyMessage';
import Gallery, { GalleryItem } from '../widgets/gallery';

const WindowContainer = styled(DialogWindow)`
	min-height: 480px;
	max-height: calc(100vh - 50px);
`;

const VariantSelectionDialog = () => {
	const { currentDialogId, closeDialog } = useDialogManager();
	const product = useZakekeProduct();
	const { T } = useZakekeTranslations();
	const changeVariant = useVariantChanger();

	const handleSelectedClick = async (item: GalleryItem<ProductVariant>) => {
		await changeVariant(item.reference.id);
		closeDialog(currentDialogId);
	};

	if (!product || product.variants.length === 0) return <EmptyMessage message={T._('No variants available.')} />;

	return (
		<Dialog
			showCloseButton={false}
			windowDecorator={WindowContainer}
			title={T._('Please choose the variant of the product.')}
			buttons={[]}
		>
			<Gallery
				items={product.variants.map((variant) => ({
					key: variant.id,
					previewUrl: variant.sides[0].thumbnailUrl,
					label: variant.name,
					reference: variant,
				}))}
				onItemSelected={handleSelectedClick}
				galleryImageHeight={isMobile() ? 120 : 140}
				galleryImageWidth={isMobile() ? 120 : 140}
			/>
		</Dialog>
	);
};

export default VariantSelectionDialog;
