import { DefaultTheme } from 'styled-components';
import { FC, useEffect, useRef } from 'react';
import { useZakekeInterfaceSettings } from '@zakeke/zakeke-customizer-react';
import createThemeFromZakekeInterfaceSettings, {
	InterfaceSettingsTheme,
} from './createThemeFromZakekeInterfaceSettings';

type ThemeUpdaterProps = {
	onUpdate: (theme: DefaultTheme) => void;
};
const ThemeUpdater: FC<ThemeUpdaterProps> = ({ onUpdate }) => {
	const interfaceSettings = useZakekeInterfaceSettings<InterfaceSettingsTheme>();
	const onUpdateRef = useRef(onUpdate);
	useEffect(() => {
		onUpdateRef.current = onUpdate;
	}, [onUpdate]);
	useEffect(() => {
		onUpdateRef.current(createThemeFromZakekeInterfaceSettings(interfaceSettings));
	}, [interfaceSettings]);
	return null;
}

export default ThemeUpdater;
