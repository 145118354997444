import { useState, useEffect, useMemo } from 'react';
import {
	TemplateCategoryItem,
	useZakekeTemplateCategories,
	useZakekeTranslations,
} from '@zakeke/zakeke-customizer-react';
import useSelectedVariant from './useSelectedVariant';

interface SelectedTemplateCategoryOption {
	label: string;
	value: string;
	templates: TemplateCategoryItem[];
}

const useTemplatesPerVariant = () => {
	const macroCategories = useZakekeTemplateCategories();
	const { T } = useZakekeTranslations();
	const [selectedCategory, setSelectedCategory] = useState<SelectedTemplateCategoryOption | null>(null);
	const isSingleCategory = macroCategories.length === 1 && macroCategories[0].categories.length === 1;
	const selectedVariant = useSelectedVariant();

	useEffect(() => {
		if (isSingleCategory) {
			setSelectedCategory({
				label: macroCategories[0].categories[0].name,
				value: macroCategories[0].categories[0].id,
				templates: macroCategories[0].categories[0].templates,
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [macroCategories]);

	const categoriesOptions = useMemo(() => macroCategories.map((x) => ({
		options: x.categories.map((y) => ({
			value: y.id,
			label: y.name || T._('Other'),
			templates: y.templates,
		})),
		label: x.name || T._('Other'),
	})), [T, macroCategories]);

	const templatesPerVariant = useMemo(() => categoriesOptions
		.map((r) => r.options.map((o) => o.templates.filter((t) => (
						t.colors.length === 0 ||
						t.colors.some((c) => {
							return c.colorID === selectedVariant?.id;
						})
					))))
		.flat(3), [categoriesOptions, selectedVariant?.id]);

	const templatesPerVariantBySelectedCategory = useMemo(() => selectedCategory?.templates.filter((t) => {
		return (
			t.colors.length === 0 ||
			t.colors.some((c) => {
				return c.colorID === selectedVariant?.id;
			})
		);
	}) ?? [], [selectedCategory?.templates, selectedVariant?.id]);

	const templatesPerVariantAvailable = templatesPerVariant.length > 0;


	return {
		selectedCategory,
		setSelectedCategory,
		isSingleCategory,
		categoriesOptions,
		templatesPerVariant,
		templatesPerVariantAvailable,
		templatesPerVariantBySelectedCategory,
		macroCategories,
	};
};

export default useTemplatesPerVariant;
