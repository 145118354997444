import React, { FC, useContext } from 'react';
import { darken, lighten } from 'polished';
import styled, { css, ThemeContext } from 'styled-components';
import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';

import { MOBILE_BREAKPOINT_PX } from '../../../shared/helpers';
import { ReactComponent as DeleteIcon } from '../../../shared/icons/delete.svg';
import { ReactComponent as RecolorIcon } from '../../../shared/icons/recolor.svg';
import { Icon, Stack } from '../../globals';
import { PrimaryButton } from '../button';
import Spinner from '../spinner';
import LoadMore, { GalleryLoaderBlock } from './loadMore';
import { useAppStore } from '../../../state/store';
import { usePrintMethodForSide } from '../../../hooks/usePrintMethods';

const GalleryContainer = styled.div<{ $horizontalPad?: boolean }>`
	flex: 1;
	min-height: 0;
	overflow-y: auto;
	padding-bottom: 20px;

	${(props) =>
		props.$horizontalPad &&
		css`
			padding-left: ${props.theme.spacing.horizontalPadder};
			padding-right: ${props.theme.spacing.horizontalPadder};
			@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
				padding-left: ${props.theme.spacing.horizontalPadderMobile};
				padding-right: ${props.theme.spacing.horizontalPadderMobile};
			}
		`}
`;

const GalleryList = styled.ul<{ $galleryImageWidth: number; $viewAsList?: boolean }>`
	display: grid;
	gap: 16px;
	margin: 0;
	padding: 0;
	list-style: none;
	${(props) =>
		props.$viewAsList
			? `grid-template-columns: repeat(auto-fill, minmax(700px, 1fr)); 
				@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
					grid-template-columns: 1fr;
				}`
			: `grid-template-columns: repeat(auto-fill, minmax(${props.$galleryImageWidth}px, 1fr));
	`}
`;

const GalleryItemContainer = styled.div<{ $galleryImageHeight: number; $galleryImagePadding: number }>`
	width: 100%;
	margin-bottom: 10px;
	position: relative;
	isolation: isolate;

	${(props) => `
		height: ${props.$galleryImageHeight}px;
	`}

	&:before,
	&:after {
		display: block;
		content: '';
		position: absolute;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		border-radius: ${(props) => props.theme.measures.borderRadius};
		background-color: ${(props) => props.theme.colors.backgroundPreview};
	}
	img,
	svg {
		width: 100%;
		height: 100%;
		${(props) => `
		padding: ${props.$galleryImagePadding}px;
	`}
		object-fit: contain;
		border-radius: ${(props) => props.theme.measures.borderRadius};
		border: 1px solid ${(props) => props.theme.colors.backgroundPreview};
	}
`;

const GalleryItemStyled = styled.li<{ $viewAsList?: boolean }>`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	position: relative;

	${(props) =>
		props.$viewAsList &&
		css`
			gap: 16px;
			flex-direction: row;
			${GalleryItemContainer} {
				height: 48px !important;
				width: 100px;
				margin: 0;
			}
		`}

	@media (hover) {
		&:hover {
			${GalleryItemContainer} {
				img,
				svg {
					border-color: ${(props) => props.theme.colors.primary};
				}
			}
		}
	}
`;

const GalleryItemName = styled.span`
	font-size: 12px;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	display: block;
	width: 100%;
`;

const GalleryItemDelete = styled(Icon)`
	position: absolute;
	height: 28px;
	width: 28px;
	top: 8px;
	right: 8px;
	padding: 4px;
	cursor: pointer;
	color: ${(props) => props.theme.colors.iconMain};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	border-radius: 50%;

	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;

const GalleryErrorMessage = styled.div`
	width: 100%;
	text-align: center;
	padding: 20px;
	color: ${(props) => props.theme.colors.error};
`;

const StyledSpinner = styled(Spinner)`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${(props) => props.theme.colors.backgroundMain};
	opacity: 0.5;
	z-index: 10;
`;

const RecolorIconContainer = styled(Icon)`
	position: absolute;
	bottom: 32px;
	right: 8px;
	z-index: 1;
`;

export interface GalleryItem<T> {
	key: number | string;
	previewUrl: string;
	label: string;
	reference: T;
	isFromSeller?: boolean;
}

export interface GalleryProps<T> {
	items: GalleryItem<T>[];
	customPreview?: (item: GalleryItem<T>) => React.ReactNode;
	onItemSelected: (item: GalleryItem<T>) => void;
	onItemDelete?: (item: GalleryItem<T>) => Promise<void>;
	horizontalPad?: boolean;
	isLoading?: boolean;
	hasMore?: boolean;
	onLoadMore?: () => Promise<void> | undefined;
	onRetry?: () => void;
	hasError?: boolean;
	errorMessage?: React.ReactNode;
	galleryImageWidth?: number;
	galleryImageHeight?: number;
	galleryImagePadding?: number;
	viewAsList?: boolean;
}

const Gallery = <Data,>({
	items,
	customPreview,
	onItemSelected,
	onItemDelete,
	horizontalPad,
	isLoading,
	hasMore,
	onLoadMore,
	onRetry,
	hasError,
	errorMessage,
	galleryImageWidth = 120,
	galleryImageHeight = 120,
	galleryImagePadding = 8,
	viewAsList = false,
}: GalleryProps<Data>) => {
	const { T } = useZakekeTranslations();
	const [selectedSideId] = useAppStore((x) => [x.selectedSideId]);
	const printMethod = usePrintMethodForSide(selectedSideId);

	return (
		<GalleryContainer $horizontalPad={horizontalPad}>
			{isLoading && <StyledSpinner />}
			<GalleryList $galleryImageWidth={galleryImageWidth} $viewAsList={viewAsList}>
				{items.map((item, idx) => {
					const itemRef = item.reference as any;
					return (
						<GalleryItemStyled
							key={item.key}
							onClick={() => {
								onItemSelected(item);
							}}
							$viewAsList={viewAsList}
						>
							{itemRef &&
								itemRef.type === 'Vector' &&
								!itemRef.containsRaster &&
								printMethod.isSvgRecolorEnabled && (
									<RecolorIconContainer>
										<RecolorIcon />
									</RecolorIconContainer>
								)}
							<GalleryItemContainer
								$galleryImageHeight={galleryImageHeight}
								$galleryImagePadding={galleryImagePadding}
							>
								{customPreview?.(item) ?? <img src={item.previewUrl} alt='' />}
							</GalleryItemContainer>
							<GalleryItemName>{item.label}</GalleryItemName>
							{onItemDelete && (
								<GalleryItemDelete
									onClick={(e) => {
										e.stopPropagation();
										onItemDelete(item);
									}}
								>
									<DeleteIcon />
								</GalleryItemDelete>
							)}
						</GalleryItemStyled>
					);
				})}
				{!hasError && hasMore && onLoadMore && (
					<LoadMore isLoading={isLoading} onLoadMore={onLoadMore} $galleryImageHeight={galleryImageHeight} />
				)}
			</GalleryList>
			{hasError && (
				<Stack>
					<GalleryErrorMessage>{errorMessage || 'Generic Error'}</GalleryErrorMessage>
					{onRetry && <PrimaryButton onClick={onRetry}>{T._('Retry')}</PrimaryButton>}
				</Stack>
			)}
		</GalleryContainer>
	);
};

export const GalleryLoader: FC<{
	horizontalPad?: boolean;
	galleryImageWidth?: number;
	galleryImageHeight?: number;
}> = ({ horizontalPad, galleryImageWidth = 120, galleryImageHeight = 120 }) => {
	const themeProps = useContext(ThemeContext);
	const backColor = themeProps ? darken(0.035, themeProps.colors.backgroundMain) : '#f5f6f7';
	const foreColor = themeProps ? lighten(0.01, themeProps.colors.backgroundMain) : '#eee';

	return (
		<GalleryContainer $horizontalPad={horizontalPad}>
			<GalleryList $galleryImageWidth={galleryImageWidth}>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
				<GalleryLoaderBlock
					$galleryImageHeight={galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
			</GalleryList>
		</GalleryContainer>
	);
};

export default Gallery;
