import React, { ErrorInfo } from 'react';


type CustomErrorBoundaryContext = {
	clearError: () => void;
}
export type CustomErrorBoundaryProps = {
	render: (error: Error, context: CustomErrorBoundaryContext) => React.ReactNode;
	children?: React.ReactNode;
}
type CustomErrorBoundaryState = {
	error: Error | null;
}
export class CustomErrorBoundary extends React.Component<CustomErrorBoundaryProps, CustomErrorBoundaryState> {
	constructor(props: CustomErrorBoundaryProps) {
		super(props);

		this.state = {
			error: null,
		};
	}

	static getDerivedStateFromError(error: any) {
		return { error };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error('ErrorBoundary caught an error: ', error, errorInfo);
	}

	clearError() {
		this.setState({ error: null });
	}

	render() {
		if (this.state.error) {
			return this.props.render(this.state.error, {
				clearError: this.clearError.bind(this),
			});
		}

		return <>{this.props.children}</>;
	}
}
