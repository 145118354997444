import { Button, PrimaryButton, FooterButton } from '../button';
import { FormControl, Icon, Input, Stack } from '../../globals';
import { MOBILE_BREAKPOINT_PX } from '../../../shared/helpers';
import React, {
	FunctionComponent,
	Suspense,
	createContext,
	createRef,
	useContext,
	useEffect,
	useState,
	ReactElement,
} from 'react';

import { ReactComponent as CloseIcon } from '../../../shared/icons/times-solid.svg';
import { createPortal } from 'react-dom';
import { dialogsPortal } from '../../../shared/portals';
import { omit } from 'lodash';
import styled, { css } from 'styled-components';
import { useAppStore } from '../../../state/store';
import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';

export const dialogContext = createContext({ dialogId: '' });

export function useDialogManager() {
	const [addDialog, removeDialog] = useAppStore((x) => [x.addDialog, x.removeDialog]);
	const { dialogId } = useContext(dialogContext);

	return {
		currentDialogId: dialogId,
		showDialog: addDialog,
		closeDialog: removeDialog,
	};
}

// #region Styled comp

const DialogOverlay = styled.div`
	position: fixed;
	background-color: ${(props) => props.theme.dialogs.backdrop};
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 40px;
	color: ${(props) => props.theme.colors.textMain};
	overflow: auto;
	z-index: 30;

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: 20px;
		background-color: rgba(0, 0, 0, 0.5);
	}
`;

const DialogWindowContainer = styled.div<{ $hasTitle?: boolean }>`
	margin-block: auto;
	background-color: transparent;
	border-radius: ${(props) => props.theme.measures.borderRadius};
	flex-basis: 800px;
	display: flex;
	flex-direction: column;
	position: relative;
	max-width: 100%;
`;

const DialogWindowClose = styled(Icon)`
	cursor: pointer;
	font-size: 20px;
	color: ${(props) => props.theme.colors.iconSecondary};

	svg {
		width: 20px;
		height: 20px;
	}
	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.iconSecondaryState};
		}
	}
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		right: 25px;
		top: 25px;
	}
`;

// Side padding is for select outlines that must not be cropped
const DialogContent = styled.div<{ $hasFooter?: boolean }>`
	color: ${(props) => props.theme.colors.textMain};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	max-height: calc(100vh - 145px);
	padding: 24px;
	border-radius: ${(props) =>
		props.$hasFooter
			? `calc(${props.theme.measures.borderRadius} * 6) calc(${props.theme.measures.borderRadius} * 6) 0 0`
			: `calc(${props.theme.measures.borderRadius} * 6)`};
	overflow-y: auto;
	flex: 1;
	min-height: 0;
	& * {
		max-width: 100%;
	}
`;

const dialogFooterButtonCss = css`
	min-width: 120px;

	@media screen and (max-width: 768px) {
		font-size: 12px;
		min-width: 50px;
		padding: 10px;
	}
`;

const DialogFooterIconButton = styled(FooterButton)`
	${dialogFooterButtonCss}
`;

const DialogFooterPrimaryButton = styled(PrimaryButton)`
	${dialogFooterButtonCss}
`;

const DialogFooterButton = styled(Button)<{ outline?: boolean }>`
	${dialogFooterButtonCss}
	color: ${(props) => (props.outline ? props.theme.colors.textMain : props.theme.colors.primary)};
	font-weight: bold;

	&:hover {
		opacity: 0.7;
	}
`;

export const DialogFooter = styled.div<{ $justifyContent?: string }>`
	display: flex;
	position: relative;
	align-items: center;
	flex-wrap: wrap;
	gap: 20px;
	padding: 24px;
	color: ${(props) => props.theme.colors.textMain};
	background-color: ${(props) => props.theme.colors.backgroundMain};
	border-radius: 0 0 calc(${(props) => props.theme.measures.borderRadius} * 6)
		calc(${(props) => props.theme.measures.borderRadius} * 6);
	justify-content: ${(props) => props.$justifyContent || 'flex-end'};

	@media screen and (max-width: 768px) {
		gap: 10px;
	}

	&::before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		inset-inline: 24px;
		height: 1px;
		background-color: ${(props) => props.theme.colors.borders};
	}
`;

const DialogLeftFooterCustomComponentContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	flex: 1;
`;

const DialogTitle = styled.h1`
	font-size: 16px;
	line-height: 24px;
	text-align: left;
	margin: 0;
	padding: 0 30px 10px 0;
	width: 100%;
`;

const DialogTitlePlaceholder = styled.div`
	min-height: 34px;
	width: 100%;
`;

const GrayContainer = styled.div<{ $hasTitle?: boolean }>`
	padding-top: ${'12px' /*(props) => (props.$hasTitle ? '24px' : '0')*/};
	margin-block: auto;
	color: ${(props) => props.theme.colors.textSecondary};
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	box-shadow: ${(props) => props.theme.dialogs.shadows};
	overflow: hidden;
`;

// #region Dialog window

interface DialogWindowProps {
	className?: string;
	showCloseButton?: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	align?: 'center' | 'left' | 'right';
	$hasTitle?: boolean;
}

export const DialogWindow: FunctionComponent<DialogWindowProps> = ({
	children,
	className,
	showCloseButton = true,
	onClose,
	$hasTitle,
}) => {
	return (
		<DialogWindowContainer className={className} $hasTitle={$hasTitle}>
			{children}
		</DialogWindowContainer>
	);
};

export interface DialogButton {
	label?: string;
	outline?: boolean;
	primary?: boolean;
	disabled?: boolean;
	small?: boolean;
	dataTrack?: string;
	onClick?: () => void;
	icon?: ReactElement;
}

interface DialogProps {
	showCloseButton?: boolean;
	children?: React.ReactNode;
	onClose?: () => void;
	title?: string;
	windowDecorator?: React.FunctionComponent<any>;
	buttons?: DialogButton[];
	leftFooterCustomComponent?: React.ReactNode;
	alignTop?: boolean;
	align?: 'center' | 'left' | 'right';
	justifyFooter?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
}

// const DivAlignRight = styled.div`
// 	justify-content: flex-end;
// 	display: flex;
// `;

interface MessageDialogProps {
	showButtons?: boolean;
}

// #region Dialog Comp
export const Dialog = React.forwardRef<HTMLDivElement, DialogProps>((props, ref) => {
	const Window = props.windowDecorator || DialogWindow;
	const [removeDialog] = useAppStore((x) => [x.removeDialog]);
	const { dialogId } = useContext(dialogContext);

	const onClose = props.onClose || (() => removeDialog(dialogId));

	return (
		<DialogOverlay
		// Scommentare se richiesta la chiusura del dialog cliccando sul backdrop
		// onClick={(e) => {
		// 	e.preventDefault();
		// 	if (e.currentTarget === e.target && props.showCloseButton !== false) onClose();
		// }}
		>
			{React.createElement(
				Window,
				{ onClose, showCloseButton: props.showCloseButton, $hasTitle: !!props.title, id: 'window' },
				<React.Fragment>
					<GrayContainer $hasTitle={!!props.title}>
						{
							/*!!props.title && ( */
							<Stack $horizontal={true} $marginLeft={24} $marginRight={24}>
								{props.title ? <DialogTitle>{props.title}</DialogTitle> : <DialogTitlePlaceholder />}
								{props.showCloseButton && (
									<DialogWindowClose onClick={onClose}>
										<CloseIcon />
									</DialogWindowClose>
								)}
							</Stack>
							/* )} */
						}
						<DialogContent ref={ref} $hasFooter={props.buttons && props.buttons?.length > 0}>
							{/*props.showCloseButton && !props.title?.length && (
								<DivAlignRight>
									<DialogWindowClose onClick={onClose}>
										<CloseIcon />
									</DialogWindowClose>
								</DivAlignRight>
							)*/}
							<Suspense fallback={'Loading...'}>{props.children}</Suspense>
						</DialogContent>
						{props.buttons && props.buttons.length > 0 && (
							<>
								<DialogFooter $justifyContent={props.justifyFooter}>
									{props.leftFooterCustomComponent && (
										<DialogLeftFooterCustomComponentContainer>
											{props.leftFooterCustomComponent}
										</DialogLeftFooterCustomComponentContainer>
									)}

									{props.buttons.map(printModalFooterButtons)}
								</DialogFooter>
							</>
						)}
					</GrayContainer>
				</React.Fragment>,
			)}
		</DialogOverlay>
	);
});

export const printModalFooterButtons = (button: DialogButton) => {
	const ButtonComponent = button.primary
		? DialogFooterPrimaryButton
		: button.icon
		? DialogFooterIconButton
		: DialogFooterButton;

	return (
		<ButtonComponent
			key={button.label}
			outline={button.outline}
			disabled={button.disabled}
			data-track={button.dataTrack}
			onClick={() => button.onClick?.()}
			small={button.small}
			icon={button.icon}
		>
			{button.label}
		</ButtonComponent>
	);
};

export const DialogsRenderer: FunctionComponent<{}> = (props) => {
	const [dialogs] = useAppStore((x) => [x.dialogs]);
	return (
		<>
			{createPortal(
				dialogs.map(([id, dialog]) => (
					<dialogContext.Provider key={id} value={{ dialogId: id }}>
						{dialog}
					</dialogContext.Provider>
				)),
				dialogsPortal,
			)}
		</>
	);
};

// #region Basic dialogs

export const BasicDialogWindow = styled(DialogWindow)`
	max-width: 600px;
	max-height: calc(100vh - 80px);
`;

export const MessageDialog: FunctionComponent<{ message: React.ReactNode } & DialogProps & MessageDialogProps> = ({
	message,
	showButtons,
	...props
}) => {
	const { closeDialog } = useDialogManager();
	const { dialogId } = useContext(dialogContext);

	return (
		<Dialog
			showCloseButton={true}
			windowDecorator={BasicDialogWindow}
			buttons={
				showButtons !== false
					? [
							{
								label: 'OK',
								primary: true,
								small: true,
								onClick: () => {
									closeDialog(dialogId);
									props.onClose?.();
								},
							},
					  ]
					: []
			}
			{...props}
		>
			<MessageContent>{message}</MessageContent>
		</Dialog>
	);
};

const MessageContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const QuestionDialog: FunctionComponent<
	{
		message: React.ReactNode;
		buttonYesLabel?: string;
		buttonNoLabel?: string;
		onYesClick?: () => void;
		onNoClick?: () => void;
	} & DialogProps
> = ({ message, buttonYesLabel, buttonNoLabel, onYesClick, onNoClick, title, ...props }) => {
	const { closeDialog } = useDialogManager();
	const { dialogId } = useContext(dialogContext);
	const { T } = useZakekeTranslations();

	return (
		<Dialog
			windowDecorator={BasicDialogWindow}
			title={title}
			buttons={[
				{
					label: buttonNoLabel || T._('No'),
					outline: true,
					small: true,
					onClick: onNoClick || (() => closeDialog(dialogId)),
				},
				{
					label: buttonYesLabel || T._('Yes'),
					primary: true,
					small: true,
					onClick: onYesClick || (() => closeDialog(dialogId)),
				},
			]}
			{...props}
		>
			<p>{message}</p>
		</Dialog>
	);
};

type InputControlProps = React.HTMLProps<HTMLInputElement>;

type InputDialogProps = {
	label: string;
	onConfirm: (value: string) => void;
	buttonLabel?: string;
} & DialogProps &
	InputControlProps;

export const InputDialog: FunctionComponent<InputDialogProps> = ({
	label,
	defaultValue,
	buttonLabel,
	onConfirm,
	...props
}) => {
	const { T } = useZakekeTranslations();
	const [value, setValue] = useState((defaultValue as string) || '');
	const inputProps = props as Omit<InputControlProps, 'ref' | 'as'>;
	const inputRef = createRef<HTMLInputElement>();

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') onConfirm(value);
	};

	useEffect(() => {
		inputRef.current?.focus();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Dialog
			windowDecorator={BasicDialogWindow}
			buttons={[
				{
					label: buttonLabel || T._('Confirm'),
					primary: true,
					small: true,
					onClick: () => {
						onConfirm(value);
					},
				},
			]}
			{...omit(props, 'ref')}
		>
			<FormControl label={label}>
				<Input
					ref={inputRef}
					onKeyDown={handleKeyDown}
					value={value}
					onChange={(e) => setValue(e.currentTarget.value)}
					{...inputProps}
				/>
			</FormControl>
		</Dialog>
	);
};

// #endregion
