import { FC, useContext, useEffect, useRef } from 'react';
import { darken, lighten } from 'polished';
import ContentLoader from 'react-content-loader';
import { ThemeContext } from 'styled-components';

import useIntersectionObserverRef from '../../../hooks/useIntersectionObserverRef';

type LoadMoreProps = {
	onLoadMore: () => Promise<void> | void;
	isLoading?: boolean;
	$galleryImageHeight?: number;
};
/**
 * load more items while exists and is visible in window
 */
const LoadMore: FC<LoadMoreProps> = ({ onLoadMore, $galleryImageHeight = 120, isLoading }) => {
	const isIntersecting = useRef(false);
	// prevent request after component unmounted.
	const isUnmounted = useRef(false);
	// prevent multiple requet initialization (isItersectiong could change more times while async function is running)
	const initialized = useRef(false);
	const loadMore = useRef(onLoadMore);
	
	
	const themeProps = useContext(ThemeContext);
	const backColor = themeProps ? darken(0.035, themeProps.colors.backgroundMain) : '#f5f6f7';
	const foreColor = themeProps ? lighten(0.01, themeProps.colors.backgroundMain) : '#eee';
	
	const [ref] = useIntersectionObserverRef(async (entries) => {
		isIntersecting.current = entries[0].isIntersecting;
		if (!initialized.current && isIntersecting.current && !isUnmounted.current) {
			initialized.current = true;
			(async () => {
				while (!isUnmounted.current && isIntersecting.current && initialized.current) {
					try {
						await loadMore.current();
					} catch (e) {
						console.log(e);
					}
					await new Promise((r) => setTimeout(r, 100));
				}
				initialized.current = false;
			})();
		}
	});
	
	useEffect(() => {
		loadMore.current = onLoadMore;
	}, [onLoadMore]);

	useEffect(() => {
		isUnmounted.current = false;
		return () => {
			isUnmounted.current = true;
		};
	}, []);


	return (
		<>
			<div ref={ref}>
				<GalleryLoaderBlock
					$galleryImageHeight={$galleryImageHeight}
					$backgroundColor={backColor}
					$foregroundColor={foreColor}
				/>
			</div>
			<GalleryLoaderBlock
				$galleryImageHeight={$galleryImageHeight}
				$backgroundColor={backColor}
				$foregroundColor={foreColor}
			/>
			<GalleryLoaderBlock
				$galleryImageHeight={$galleryImageHeight}
				$backgroundColor={backColor}
				$foregroundColor={foreColor}
			/>
			<GalleryLoaderBlock
				$galleryImageHeight={$galleryImageHeight}
				$backgroundColor={backColor}
				$foregroundColor={foreColor}
			/>
			<GalleryLoaderBlock
				$galleryImageHeight={$galleryImageHeight}
				$backgroundColor={backColor}
				$foregroundColor={foreColor}
			/>
			<GalleryLoaderBlock
				$galleryImageHeight={$galleryImageHeight}
				$backgroundColor={backColor}
				$foregroundColor={foreColor}
			/>
		</>
	);
};
export default LoadMore;

export const GalleryLoaderBlock: FC<{
	$galleryImageHeight?: number;
	$backgroundColor: string;
	$foregroundColor: string;
}> = ({ $galleryImageHeight = 120, $backgroundColor, $foregroundColor }) => (
	<ContentLoader
		width={'100%'}
		height={`${$galleryImageHeight}px`}
		backgroundColor={$backgroundColor}
		foregroundColor={$foregroundColor}
	>
		<rect x='0' y='0' rx='4' ry='4' width='100%' height={`${$galleryImageHeight}px`} />
		<rect x='0' y='130px' rx='4' ry='4' width='50%' height='14.5px' />
	</ContentLoader>
);
