import { atom, SetStateAction } from 'jotai';

type AtomWithListnersCallbackHelpers<Value> = {
	setSelf: (newVal: SetStateAction<Value>) => void;
};
export type AtomWithListnersCallback<Value> = (
	newValue: Value,
	prevValue: Value,
	options: AtomWithListnersCallbackHelpers<Value>,
) => void;
export default function atomWithListeners<Value>(
	initialValue: Value,
	listeners: AtomWithListnersCallback<Value>[] = [],
) {
	const baseAtom = atom(initialValue);
	return atom(
		(get) => get(baseAtom),
		(get, set, arg: Value) => {
			const prevVal = get(baseAtom);
			set(baseAtom, arg);
			const newVal = get(baseAtom);
			listeners.forEach((callback) => {
				callback(newVal, prevVal, {
					setSelf: (newVal) => set(baseAtom, newVal),
				});
			});
		},
	);
}
