import { Design, Image } from '@zakeke/zakeke-customizer-react';
import {
	TextElement,
	ImageElement,
	CustomizerElement,
	ShapeElement,
	TextArtElement,
	isTextElement,
	isImageElement,
} from '../components/interfaces';

/*
mantenere qui solo le funzioni usate dentro la cartella degli hook ?
*/

const mapCommon = (item: Design['texts'][0] | Design['images'][0] | Design['shapes'][0] | Design['textArts'][0]) => {
	return {
		guid: item.guid,
		sideId: item.sideId,
		syncGuid: item.syncGuid,
		tag: item.tag,
		tagType: item.tagType,
		name: item.name,
		realPositionX: item.positionX,
		realPositionY: item.positionY,
		realRotation: item.rotation,
		matrix: item.matrix,
		realMatrix: item.matrix,
		positionX: item.positionX,
		positionY: item.positionY,
		rotation: item.rotation,
		width: item.width,
		height: item.height,
		replaceWidth: item.width,
		replaceHeight: item.height,
		index: item.index,
		constraints: item.constraints,
		isFromTemplate: false,
		collageBoxId: item.collageBoxId,
	};
};

export const mapLibraryItemsToUIItems = async (
	design: Partial<Design>,
	getImage: (id: number) => Promise<Image>,
	adaptItem?: (item: CustomizerElement) => Promise<CustomizerElement>,
) => {
	const texts =
		design.texts?.map((x) => {
			const { matrix: _matrix, positionX, positionY, ...commonParse } = mapCommon(x);
			// fix text initial position.
			const matrix = _matrix?.moveItemFromTo(_matrix.translation.x, _matrix.translation.y, positionX, positionY);

			return {
				type: 'text',
				content: x.text,
				color: x.color,
				fontFamily: x.fontFamily,
				fontSize: Math.round(x.fontSize * 100) / 100,
				bold: x.bold,
				italic: x.italic,
				justification: x.justification,
				verticalAlignment: x.verticalAlignment,
				lineSpacing: x.lineSpacing,
				letterSpacing: x.letterSpacing,
				shadowAngle: x.shadowAngle,
				shadowBlur: x.shadowBlur,
				shadowColor: x.shadowColor,
				shadowDistance: x.shadowDistance,
				isTextBox: x.isTextBox,
				adaptTextBoxFontSize: x.adaptTextBoxFontSize,
				textBoxRectangle: x.textBoxRectangle,
				isCurvedText: x.isCurvedText,
				curvedTextPointFirst: x.curvedTextPointFirst,
				curvedTextPointMiddle: x.curvedTextPointMiddle,
				curvedTextPointEnd: x.curvedTextPointEnd,
				strokeColor: x.strokeColor,
				strokeWidth: x.strokeWidth,
				positionX,
				positionY,
				matrix,
				...commonParse,
			} as TextElement;
		}) ?? [];

	const images =
		design.images?.map(
			(x) =>
				({
					type: 'image',
					imageId: x.imageId,
					initialColors: [],
					colors: x.colors,
					colorMappings: x.colorMappings,
					isClipart: false,
					flipX: x.flipX,
					flipY: x.flipY,
					maskShapeId: x.maskShapeId,
					maskShapeStrokeColor: x.maskShapeStrokeColor,
					maskShapeStrokeWidth: x.maskShapeStrokeWidth,
					maskShapeMatrix: x.maskShapeMatrix,
					maskShapeInitialBounds: x.maskShapeInitialBounds,
					isVector: x.imageType === 'Vector',
					isBackgroundForArea: x.isBackgroundForArea,
					preferredHeight: x.preferredHeight,
					preferredWidth: x.preferredWidth,
					...mapCommon(x),
				} as ImageElement),
		) ?? [];
	const colorsPromises = images.map(async (image) => {
		const imageDetails = await getImage(image.imageId);
		image.initialColors = imageDetails.colors.map((color) => color.code);
	});

	await Promise.all(colorsPromises);

	await Promise.all(colorsPromises);

	const shapes =
		design.shapes?.map(
			(x) =>
				({
				type: 'shape',
				shapeId: x.shapeId,
				fillColor: x.fillColor,
				strokeColor: x.strokeColor,
				strokeWidth: x.strokeWidth,
				scaleX: x.scaleX,
				scaleY: x.scaleY,
				isFillable: x.isFillable,
				filledImageId: x.filledImageId,
				filledImagePositionX: x.filledImagePositionX,
				filledImagePositionY: x.filledImagePositionY,
				filledImageRotation: x.filledImageRotation,
				filledImageScale: x.filledImageScale,
				filledImageColors: x.filledImageColors,
				filledImageColorMapping: x.filledImageColorMapping,
				filledImageMatrix: x.filledImageMatrix,
				filledImageInitialColors: [],

				...mapCommon(x),
			} as ShapeElement),
	) ?? [];

	const shapesColorsPromises = shapes.map(async (shape) => {
		if (!shape.filledImageId) return;
		const imageDetails = await getImage(shape.filledImageId);
		shape.filledImageInitialColors = imageDetails.colors.map((color) => color.code);
	});

	await Promise.all(shapesColorsPromises);

	const textArts =
		design.textArts?.map(
			(x) =>
				({
					type: 'text-art',
					fillColor: x.fillColor,
					strokeColor: x.strokeColor,
					strokeWidth: x.strokeWidth,
					content: x.text,
					fontFaceId: x.fontFaceId,
					fontFamilyId: x.fontFamilyId,
					configuration: x.configuration,
					typeId: x.typeId,
					...mapCommon(x),
				} as TextArtElement),
		) ?? [];

	let itemsToReturn = (texts as CustomizerElement[])
		.concat(images as CustomizerElement[])
		.concat(shapes as CustomizerElement[])
		.concat(textArts as CustomizerElement[]);

	if (!!adaptItem)
		return await Promise.all(
			itemsToReturn.map(async (item) => {
				const adaptedItem = adaptItem(item);
				return adaptedItem;
			}),
		);

	return itemsToReturn;
};

export const isItemStatic = (item: CustomizerElement): boolean => {
	if (!item.constraints) return false;

	let isStatic = !(
		(item.constraints.canEdit ?? true) ||
		(item.constraints.canMove ?? true) ||
		(item.constraints.canRotate ?? true) ||
		(item.constraints.canResize ?? true) ||
		(item.constraints.canDelete ?? true) ||
		(item.constraints.canTransform ?? true)
	);

	if (isImageElement(item) && item.isVector) {
		isStatic = isStatic && !(item.constraints.canChangeSvgColors ?? true);
	}

	if (isTextElement(item)) {
		isStatic =
			isStatic &&
			!(
				(item.constraints.canChangeTextStrokeColor ?? true) ||
				(item.constraints.canChangeTextStrokeWidth ?? true) ||
				(item.constraints.canChangeFontFamily ?? true) ||
				(item.constraints.canChangeFontColor ?? true) ||
				(item.constraints.canChangeFontWeight ?? true) ||
				(item.constraints.canChangeTextPathMode ?? true)
			);
	}

	return isStatic;
};
export function getButtonsListFiltered<
	T extends {
		key: string;
		type?: string;
		show: boolean;
	},
	F extends {
		key?: T['key'][];
		type?: T['type'];
	},
>(toolButtons: T[]) {
	function _filterButtons({
		filterIn, // filtra per mostrare i bottoni specifici.
		filterOut, // filtra per nascondere i bottoni specifici.
		ignoreVisibility = false, // se true, non filtra per show ( permettendo di filtrare poi )
	}: {
		filterIn?: F;
		filterOut?: F;
		ignoreVisibility?: boolean;
	}): T[] {
		return toolButtons.filter((button) => {
			let filteredIn = true;
			let filteredOut = false;

			if (filterIn) {
				filteredIn =
					(filterIn.key ? filterIn.key.includes(button.key) : false) ||
					(filterIn.type ? filterIn.type === button.type : false);
			}
			if (filterOut) {
				filteredOut =
					(filterOut.key ? filterOut.key.includes(button.key) : false) ||
					(filterOut.type ? filterOut.type === button.type : false);
			}
			return filteredIn && !filteredOut && (ignoreVisibility || button.show);
		});
	}
	return _filterButtons;
}
