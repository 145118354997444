import {
	CustomizerItemBackground,
	Design,
	useZakekeHelpers,
	useZakekeProduct,
	useZakekeTranslations,
	useZakekeUsedColors,
} from '@zakeke/zakeke-customizer-react';
import { Map } from 'immutable';

import { isImageElement, isTextArtElement, isTextElement, CustomizerElement } from '../components/interfaces';
import useSelectedSide from '../hooks/useSelectedSide';
import { isMobile } from '../shared/helpers';
import { checkImageResizable } from '../shared/helpers.image';
import { useAppStore, useSetItems } from '../state/store';
import { getDefaultTextColor } from '../shared/helpers.text';
import { isItemStatic, mapLibraryItemsToUIItems } from './common';
import usePrintMethods from './usePrintMethods';
import useSelectedVariant from './useSelectedVariant';
import useVariantChanger from './useVariantChanger';
import { debounce, forEach } from 'lodash';
import useUndoRedo from './useUndoRedo';

const useAdaptTemplate = () => {
	const { getAdaptItems, getImage, addRenderedElementListenerOnce } = useZakekeHelpers();
	const setItems = useSetItems();
	const waitUntilItemRerenderStop = (guid: string) => {
		let completed = false;
		return new Promise((resolve) => {
			const complete = debounce(() => {
				completed = true;
				resolve(null);
			}, 300);
			const listener = () =>
				addRenderedElementListenerOnce(guid, () => {
					complete();
					if (!completed) {
						listener();
					}
				});
			addRenderedElementListenerOnce(guid, listener);
		});
	};
	const waitUntilItemsRerenderStops = (guids: string[]) => {
		return Promise.all(guids.map(waitUntilItemRerenderStop));
	};
	return async (currentTemplate: Design, currentVariantId: number) => {
		if (!currentTemplate || !currentVariantId) return;
		if (!currentTemplate.variantId) return;
		if (currentTemplate.variantId === currentVariantId) return;
		if (!currentTemplate.adaptItemsToVariants) return;
		const templateVariantId = currentTemplate.variantId;
		await waitUntilItemsRerenderStops([
			...currentTemplate.images.map((x) => x.guid),
			...currentTemplate.texts.map((x) => x.guid),
			...currentTemplate.textArts.map((x) => x.guid),
			...currentTemplate.shapes.map((x) => x.guid),
		]);
		const newItems = await getAdaptItems(currentVariantId, templateVariantId, currentTemplate.adaptItemsToVariants);
		if (newItems) {
			const items = await mapLibraryItemsToUIItems(newItems, getImage);
			if (items && items.length > 0) {
				setItems(() => items);
			}
		}
	};
};
const useDesignLoader = () => {
	const { adaptItemToPrintMethods } = usePrintMethods();
	const { getDesign, getImage, getSelectableColorsForText } = useZakekeHelpers();
	const product = useZakekeProduct();
	const currentVariant = useSelectedVariant();
	const changeVariant = useVariantChanger();
	const { T } = useZakekeTranslations();
	const side = useSelectedSide();
	const { resetUndoRedo } = useUndoRedo();
	const usedColors = useZakekeUsedColors();
	const adaptTemplate = useAdaptTemplate();

	const [
		setSelectedItemGuid,
		openEditMenuPageForItem,
		setMenuPage,
		setSidesCollages,
		setEditDesignId,
		setSidesPrintTypes,
		setSidesRotations,
		setPreventAdaptForSideRotation,
		removeSideAreaBackgrounds,
		setSideAreaBackgrounds,
	] = useAppStore((x) => [
		x.setSelectedItemGuid,
		x.openEditMenuPageForItem,
		x.setMenuPage,
		x.setSidesCollages,
		x.setEditDesignId,
		x.setSidesPrintMethods,
		x.setSidesRotations,
		x.setPreventAdaptForSideRotation,
		x.removeSideAreaBackgrounds,
		x.setSideAreaBackgrounds,
	]);

	const setItems = useSetItems();

	/**
	 * Apply printing method to items, decorate and set up items and backgrounds
	 * @param design The design object to apply
	 * test: remove background
	 * test: add new background
	 * test: adapt to print method
	 */
	const setBackgroundColorsFromDesign = (design: Design) => {
		// set color backgrounds
		forEach(design.backgroundColors, (backgroundColor: CustomizerItemBackground) => {
			setSideAreaBackgrounds(backgroundColor.sideId, backgroundColor.areaId, backgroundColor.fillColor, null);
		});
	};

	/**
	 * Apply printing method to items, decorate and set up items and backgrounds
	 * @param design The design object to apply
	 * @param type set if the design is a draft, template or design
	 * @param mapper trasformation function before setting items
	 * test: remove collage from all sides if exists
	 * test: adapt to print method
	 */
	const setItemsFromDesign = async (
		design: Design,
		type: 'template' | 'design',
		// type: 'draft' | 'template' | 'design' = 'draft', // al momento le draft sono gestite come i design, come avviene nel vecchio, la gestione di draft crea errori, va ricontrollata.
		mapper?: (item: CustomizerElement) => CustomizerElement,
	): Promise<CustomizerElement[]> => {
		if (!product) return [];
		const isTemplate = type === 'template';
		// const isDraft = type === 'draft';

		let variantId = currentVariant?.id;
		// should set item to empty array before await, so can do rendering
		setItems(() => [], true);
		let items = await mapLibraryItemsToUIItems(design, getImage, (item) => {
			item = { ...item, isFromTemplate: isTemplate };

			const printTypeId = ((item, design, product) => {
				// let printTypeId = isDraft
				// 	? // if is a draft adapt print method to current side's print method
				// 	  getPrintMethodForSide(item.sideId)?.id
				// 	: // if is a design get his own print method
				// 	  design.sidePrintTypes.get(item.sideId)!;

				// if is a design get his own print method
				let printTypeId = design.sidePrintTypes.get(item.sideId)!;

				// if there is no print type, get the default
				if (!printTypeId || printTypeId === -1) {
					const printType = product.variants
						.find((x) => x.id === variantId)
						?.sides.find((x) => x.id === item.sideId)?.printTypes[0];

					printTypeId = printType?.id ?? product.printTypes[0].id!;
				}
				return printTypeId;
			})(item, design, product);
			// some old templates have wrong printTypeID that dosn't exist anymore
			// the first printType is used if no one available
			const currentPrintType =
				product?.printTypes.find((x) => x.id === printTypeId) ?? product?.printTypes.at(0)!;

			// set image backgrounds
			if (isImageElement(item) && item.isBackgroundForArea) {
				setSideAreaBackgrounds(item.sideId, item.isBackgroundForArea, null, item.guid);
			}

			// setup constraints
			if (isImageElement(item) && !item.isBackgroundForArea) {
				const isClipartsImagesResizeEnabled = currentPrintType.isClipartsImagesResizeEnabled;
				item.constraints = {
					...(item.constraints || {}),
					canResize: checkImageResizable(item, !!isClipartsImagesResizeEnabled),
				};
			}

			// set default text stroke color if undefined.
			if (isTextElement(item) && !item.strokeColor) {
				item.strokeColor = getDefaultTextColor(
					currentPrintType,
					getSelectableColorsForText(item.sideId, '#00000', printTypeId, false),
					usedColors?.get(item.sideId) ?? null,
				);
			}

			if (!isTemplate) {
				// return adaptItemToPrintMethods(item, printTypeId, side?.id!, isDraft);
				return adaptItemToPrintMethods(item, printTypeId, side?.id!, false);
			}

			return Promise.resolve(item);
		});
		items = mapper ? items.map(mapper) : items;
		setItems(() => items, true);
		return items;
	};

	/**
	 * Apply the @design clearing all the other elements
	 * @param design The design object to apply
	 * @param isTemplate If the design is a template, if not then the variant will also change
	 */
	const applyDesign = async (design: Design, isTemplate: boolean) => {
		if (!product) return;

		// Get correct variant
		let variantId = currentVariant?.id;
		let variant = currentVariant;

		if (!isTemplate && design.variantId) {
			variantId = design.variantId;
		}

		// Select the first side
		if (variantId) variant = product.variants.find((x) => x.id === variantId);

		if (!variant) throw new Error(`Variant ${variantId} not found when applying design.`);

		setEditDesignId(design.id);
		await changeVariant(variant.id, {
			preventResetEnabled: true,
			preventAdapt: true,
		});

		/*
		 * In case of template created before assigning any print method to the product,
		 * design.sidePrintTypes is an empty map that creates only problems,
		 * so we prevent overwriting default assigned printTypes
		 */
		if (design.sidePrintTypes.size > 0) setSidesPrintTypes(Map(design.sidePrintTypes));

		// Rotation
		setSidesRotations(Map(design.sideRotations));
		setPreventAdaptForSideRotation(true);
		removeSideAreaBackgrounds();

		setSidesCollages(Map(design.sidesCollages));
		const translateTextualItems = (x: CustomizerElement) => {
			if (isTextArtElement(x) || isTextElement(x))
				return { ...x, content: T._d(x.content), originalContent: T._d(x.content) };
			return x;
		};
		setBackgroundColorsFromDesign(design);
		const items = await setItemsFromDesign(design, isTemplate ? 'template' : 'design', translateTextualItems);
		await adaptTemplate(design, variant.id);
		// Select the first element of the first side if only one and is somehow editable
		const firstSideItems = items.filter((x) => {
			const isItemTopOrBottom =
				(x.constraints?.isAlwaysOnTop && x.constraints.isAlwaysOnTop === true) ||
				(x.constraints?.isAlwaysOnBottom && x.constraints.isAlwaysOnBottom === true);
			return x.sideId === variant!.sides[0].id && !(isItemStatic(x) && isItemTopOrBottom);
		});

		if (firstSideItems.length === 1) {
			const itemToSelect = firstSideItems[0];

			if (itemToSelect && !isItemStatic(itemToSelect)) {
				setSelectedItemGuid(itemToSelect?.guid);

				if (!isMobile()) {
					openEditMenuPageForItem(itemToSelect, false);
				}
			} else {
				setSelectedItemGuid(null);
			}
		}

		resetUndoRedo();

		// If multiple elements, open design elements
		if (firstSideItems.length > 1 && !isMobile()) {
			setMenuPage({ page: 'design-elements' });
		}
	};

	/**
	 * Load a existing design (e.g edit design or draft)
	 * @param id The design to load
	 */
	const loadDesign = async (id: string, isOrderDesignEditor: boolean) => {
		const design = await getDesign(id, isOrderDesignEditor);
		applyDesign(design, false);
	};

	return {
		applyDesign,
		loadDesign,
		setItemsFromDesign,
		setBackgroundColorsFromDesign,
	};
};

export default useDesignLoader;
