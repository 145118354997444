import {
	WritableAtom,
} from 'jotai';
import {
	useAtomCallback,
} from 'jotai/utils';
import { Dispatch, SetStateAction, useCallback } from 'react';

const useSetAtomWithPrev = <T>(atom: WritableAtom<T, any[], any>): Dispatch<SetStateAction<T>> => {
	return useAtomCallback(useCallback((get, set, value: SetStateAction<T>) => {
		const prev = get(atom);
		// @ts-ignore
		set(atom, typeof value === 'function' ? value(prev) : value);
	}, [atom]));
}

export default useSetAtomWithPrev;