import { useZakekeHelpers, useZakekeProduct, useZakekeProductOptions } from '@zakeke/zakeke-customizer-react';
import { useAppStore, useSetItems } from '../state/store';
import { Map } from 'immutable';
import { SideOptions } from '../state/interfaces/sideOptions';
import { mapLibraryItemsToUIItems } from './common';

type ChangeVariantOptions = {
	preventResetEnabled?: boolean;
	preventAdapt?: boolean;
};
const useVariantChanger = () => {
	const product = useZakekeProduct();
	const productOptions = useZakekeProductOptions();
	const { getAdaptItems, getImage } = useZakekeHelpers();
	const setItems = useSetItems();

	return async (variantId: number, options?: ChangeVariantOptions) => {
		const {
			selectedSideId,
			sidesPrintMethods,
			resizableOptions,
			currentTemplate,
			selectedVariantId: oldVariantId,
		} = useAppStore.getState();

		const oldVariant = product.variants.find((x) => x.id === oldVariantId);
		const selectedVariant = product.variants.find((x) => x.id === variantId);

		if (!selectedVariant) throw new Error(`Invalid variant id ${variantId}`);

		const getSideId = () => {
			if (selectedVariant.sides.some((x) => x.id === selectedSideId)) {
				return selectedSideId;
			}
			return selectedVariant.sides[0].id;
		};

		const getSides = () => {
			if (oldVariant) {
				return selectedVariant.sides
					.filter((newSide) => {
						const oldAreas = oldVariant.sides.find((s) => s.id === newSide.id)?.areas;
						// avvio ricalcolo se numero di aree è diverso o se le stesse aree hanno grandezze diverse
						const sameArea = newSide.areas.find((newSideArea) => {
							return !oldAreas?.find((oldSideArea) => {
								return (
									oldSideArea.id === newSideArea.id &&
									oldSideArea.width === newSideArea.width &&
									oldSideArea.height === newSideArea.height
								);
							});
						});
						return !oldAreas || oldAreas.length !== newSide.areas.length || sameArea;
					})
					.map((i) => i.id);
			}
			return [];
		};

		const getSidesPrintMethods = () => {
			return (
				selectedVariant.sides.reduce((reviewedPrintTypes, side) => {
					const printIdForSide = reviewedPrintTypes.get(side.id);
					if (!(printIdForSide && side.printTypes.some((x) => x.id === printIdForSide))) {
						return reviewedPrintTypes.set(side.id, side.printTypes[0]?.id ?? product.printTypes[0].id);
					}
					return reviewedPrintTypes;
				}, sidesPrintMethods) ?? Map(selectedVariant.sides.map((x) => [x.id, product.printTypes[0].id]))
			);
		};

		const getSidesOptions = () => {
			if (resizableOptions.size === 0) {
				return Map(
					productOptions.map((x) => {
						let sidesOptions = Map<number, SideOptions>();
						if (x.options === null && x.sides) {
							// Side options
							sidesOptions = Map(
								x.sides
									.filter((x) => x.options)
									.map((y) => {
										return [
											y.sideId,
											{
												width: y.options?.isResizable ? y.options!.startWidth : null,
												height: y.options?.isResizable ? y.options!.startHeight : null,
												columns: y.options?.startSplitColumns ?? 1,
												rows: y.options?.startSplitRows ?? 1,
											},
										];
									}),
							);
						} else if (x.options) {
							const variant = product.variants.find((y) => y.id === selectedVariant.id)!;

							// Variant options
							sidesOptions = Map(
								variant.sides.map((y) => {
									return [
										y.id,
										{
											width: x.options?.isResizable ? x.options!.startWidth : null,
											height: x.options?.isResizable ? x.options!.startHeight : null,
											columns: x.options?.startSplitColumns ?? 1,
											rows: x.options?.startSplitRows ?? 1,
										},
									];
								}),
							);
						}

						return [x.variantId, sidesOptions];
					}),
				);
			}
			return resizableOptions;
		};

		useAppStore.setState({
			selectedVariantId: variantId,
			selectedSideId: getSideId(),
			forceResetEnabled: !options?.preventResetEnabled,
			sidesPrintMethods: getSidesPrintMethods(),
			resizableOptions: getSidesOptions(),
		});

		if (!options?.preventAdapt) {
			const sides = getSides();
			const newItems = await getAdaptItems(variantId, oldVariantId!, currentTemplate?.adaptItemsToVariants ?? 0);
			if (newItems) {
				const items = await mapLibraryItemsToUIItems(newItems, getImage);
				if (items && items.length > 0 && sides.length) {
					setItems((prev) => {
						return prev.filter((i) => !sides.includes(i.sideId)).concat(items);
					});
				}
			}
		}
	};
};

export default useVariantChanger;
