import update, { extend } from 'immutability-helper';
import React from 'react';
import App from './App';
// import './index.css';
import reportWebVitals from './reportWebVitals';
import { createRoot } from 'react-dom/client';

// Extends immutability helper
extend('$auto', function (value, object) {
  return object ?
    update(object, value) :
    update({}, value);
});
extend('$autoArray', function (value, object) {
  return object ?
    update(object, value) :
    update([], value);
});
extend('$autoMap', function (value, object) {
  return object ?
    update(object, value) :
    update(new Map(), value);
});

const root = createRoot(document.getElementById('root')!);
root.render(<React.StrictMode>
  <App />
</React.StrictMode>,);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
