import { useMemo } from 'react';
import { useAppStore } from '../state/store';
import useSelectedVariant from './useSelectedVariant';

const useSelectedSide = () => {
    const selectedVariant = useSelectedVariant();
    const selectedSideId = useAppStore(x => x.selectedSideId);

    return useMemo(() => selectedVariant?.sides.find(x => x.id === selectedSideId), [selectedSideId, selectedVariant]);
}

export default useSelectedSide;