const config = {
	facebookAppId: '1157894994331979',
	avaibleLanguages: [
		'it',
		'en',
		'es',
		'fr',
		'de',
		'af',
		'fa',
		'sq',
		'am',
		'ar',
		'hy',
		'az',
		'eu',
		'bn',
		'my',
		'bs',
		'bg',
		'ca',
		'cs',
		'ny',
		'zh',
		'zh-TW',
		'ko',
		'co',
		'hr',
		'ku',
		'da',
		'he',
		'et',
		'fil',
		'fi',
		'gl',
		'cy',
		'ka',
		'ja',
		'jv',
		'el',
		'gu',
		'ha',
		'haw',
		'hi',
		'hmn',
		'id',
		'is',
		'kn',
		'kk',
		'km',
		'ky',
		'lo',
		'lv',
		'lt',
		'lb',
		'mk',
		'ml',
		'ms',
		'mg',
		'mt',
		'mi',
		'mr',
		'mn',
		'ne',
		'no',
		'nb',
		'nl',
		'ps',
		'pl',
		'pt',
		'pa',
		'ro',
		'ru',
		'sm',
		'sr',
		'st',
		'sn',
		'sd',
		'si',
		'sk',
		'sl',
		'so',
		'su',
		'sv',
		'sw',
		'tg',
		'tai',
		'ta',
		'tr',
		'uk',
		'hu',
		'ur',
		'uz',
		'vi',
		'xh',
		'yi',
		'yo',
		'zu',
		'nb',
	],
};

export default config;
