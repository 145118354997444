import styled from 'styled-components';
import { FC } from 'react';

import { MOBILE_BREAKPOINT_PX } from '../../../shared/helpers';
import Spinner from '../spinner';

const LoadingOverlay = styled.div<{ $darkBack?: boolean }>`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 30;
	${(props) =>
		props.$darkBack ? `background-color: rgba(0, 0, 0, 0.15);` : `background-color: rgba(0, 0, 0, 0.025);`}
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		${(props) =>
			props.$darkBack ? `background-color: rgba(0, 0, 0, 0.75);` : `background-color: rgba(0, 0, 0, 0.5);`}
	}
`;

const Loading: FC<{ darkBack?: boolean }> = ({ darkBack = false }) => {
	return (
		<LoadingOverlay $darkBack={darkBack}>
			<Spinner />
		</LoadingOverlay>
	);
};

export default Loading;
