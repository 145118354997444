import Spinner from '../widgets/spinner';
import { Suspense } from 'react';
import { createPortal } from 'react-dom';
import { vtoPortal } from '../../shared/portals';
import { useAppStore } from '../../state/store';
import styled from 'styled-components';

const VtoWrapper = styled.div`
	position: fixed;
	z-index: 40;
	inset: 0;
	background-color: white;
`;

const VtoRenderer = () => {
	const [vto] = useAppStore((store) => [store.vto]);
	return createPortal(
		vto ? (
			<Suspense fallback={<Spinner />} key={vto?.[0]}>
				<VtoWrapper>{vto?.[1]}</VtoWrapper>
			</Suspense>
		) : null,
		vtoPortal,
	);
};

export default VtoRenderer;
