import { FC } from 'react';
import styled from 'styled-components';

const EmptyMessageContainer = styled.div<{ $error?: boolean }>`
	text-align: center;
	font-size: 1rem;
	color: ${(props) => (props.$error ? props.theme.colors.error : props.theme.colors.textMain)};
`;

export type EmptyMessageProps = {
	message: string;
	error?: boolean;
};
const EmptyMessage: FC<EmptyMessageProps> = ({ message, error }) => {
	return <EmptyMessageContainer $error={error}>{message}</EmptyMessageContainer>;
};

export default EmptyMessage;
