import { useZakekeHelpers, useZakekeTemplateCategories } from '@zakeke/zakeke-customizer-react';
import { useAppStore } from '../state/store';
import useDesignLoader from './useDesignLoader';

const useTemplateLoader = () => {
	const { getTemplate } = useZakekeHelpers();
	const templatesCategories = useZakekeTemplateCategories();
	const { applyDesign } = useDesignLoader();
	const [setCurrentTemplate, setIsLoading, setCurrentDraft] = useAppStore((x) => [
		x.setCurrentTemplate,
		x.setIsLoading,
		x.setCurrentDraft,
	]);

	const loadTemplate = async (id: number | string) => {
		setIsLoading(true);
		// TODO: Move to a custom hook for loading any type of design
		const template = await getTemplate(typeof id === 'string' ? parseInt(id) : id);
		setCurrentTemplate(template);
		setCurrentDraft(null);
		await applyDesign(template, true);
		setIsLoading(false);
	};

	const getDefaultTemplateForVariant = (variantId: number) => {
		let templateId: number | null = null;

		for (const macroCategory of templatesCategories) {
			for (const category of macroCategory.categories) {
				for (const template of category.templates) {
					if (template.colors.find((x) => x.colorID === variantId && x.isDefault)) {
						templateId = template.designID;
					}
				}
			}
		}

		return templateId;
	};

	const isTemplateValidForVariant = (templateId: number | string | null, variantId: number) => {
		let templateValidColors: boolean = false;

		for (const macroCategory of templatesCategories) {
			for (const category of macroCategory.categories) {
				for (const template of category.templates) {
					if (
						template.designID === templateId &&
						(template.colors.length === 0 ||
							template.colors.some((col) => {
								return col.colorID === variantId;
							}))
					) {
						templateValidColors = true;
					}
				}
			}
		}

		return templateValidColors;
	};

	const countTemplatesForVariant = (variantId: number) => {
		let count = 0;

		for (const macroCategory of templatesCategories) {
			for (const category of macroCategory.categories) {
				for (const template of category.templates) {
					if (template.colors.find((x) => x.colorID === variantId) || template.colors.length === 0) {
						count++;
					}
				}
			}
		}

		return count;
	};

	return {
		loadTemplate,
		getDefaultTemplateForVariant,
		isTemplateValidForVariant,
		countTemplatesForVariant,
	};
};

export default useTemplateLoader;
