import React, { ComponentProps, FC } from 'react';
import { desaturate, lighten } from 'polished';
import styled, { DefaultTheme } from 'styled-components';

import { MOBILE_BREAKPOINT_PX } from '../../../shared/helpers';
import { ReactComponent as DoneIcon } from '../../../shared/icons/done-fill.svg';
import { Icon, Stack } from '../../globals';

interface ButtonProps {
	icon?: React.ReactNode;
	smallRadius?: boolean;
	outline?: boolean;
	small?: boolean;
	border?: boolean;
}

interface ButtonContainerProps {
	icon?: React.ReactNode;
	$smallRadius?: boolean;
	$small?: boolean;
}

const applyStyle = (
	theme: DefaultTheme,
	type: 'primary' | 'basic' | 'icontool' | 'text' | 'alternative' | 'toggle',
	style: 'normal' | 'outline',
	selected: boolean | undefined,
	disabled: boolean | undefined,
) => {
	let $rule = '';
	// #region Basic
	if (type === 'basic') {
		//normal
		style === 'normal'
			? !disabled
				? selected
					? ($rule = `
							background-color: ${theme.colors.backgroundMain};
							border-color: ${theme.colors.backgroundMain};
							color: ${theme.colors.primary};
						`)
					: ($rule = `${$rule}
					background-color: ${theme.colors.backgroundMain};
					border-color: ${theme.colors.backgroundMain};
					color: ${theme.colors.textMain};
			
					@media (hover) {
						&:hover {
							background-color: ${theme.colors.backgroundMain};
							border-color: ${theme.colors.backgroundMain};
							color: ${theme.colors.primary};
						}
					}`)
				: ($rule = `${$rule}
					background-color: ${theme.colors.backgroundMain};
					border-color: ${theme.colors.backgroundMain};
					color: ${theme.colors.textDisabled};
				`)
			: //outline
			!disabled
			? ($rule = `${$rule}
				background-color: ${theme.colors.backgroundMain};
				border-color: ${theme.colors.textMain};
				color: ${theme.colors.textMain};

				@media (hover) {
					&:hover {
						background-color: ${theme.colors.backgroundMain};
						border-color: ${theme.colors.primary};
						color: ${theme.colors.primary};
					}
				}`)
			: ($rule = `${$rule}
				background-color: ${theme.colors.backgroundMain};
				border-color: ${theme.colors.textDisabled};
				color: ${theme.colors.textDisabled};
			`);
	}
	// #region Primary
	if (type === 'primary') {
		//normal
		style === 'normal'
			? !disabled
				? selected
					? ($rule = `
							background-color: ${theme.colors.accentState};
							border-color: ${theme.colors.accentState};
							color: ${theme.colors.textOverAccent};
						`)
					: ($rule = `${$rule}
					background-color: ${theme.colors.accent};
					border-color: ${theme.colors.accent};
					color: ${theme.colors.textOverAccent};
			
					@media (hover) {
						&:hover {
							background-color: ${theme.colors.accentState};
							border-color: ${theme.colors.accentState};
							color: ${theme.colors.textOverAccent};
						}
					}`)
				: ($rule = `${$rule}
					background-color: ${lighten(0.15, desaturate(1, theme.colors.accent))};
					border-color: ${lighten(0.15, desaturate(1, theme.colors.accent))};
					color: ${lighten(0.15, desaturate(1, theme.colors.textOverAccent))};
				`)
			: //outline
			!disabled
			? ($rule = `${$rule}
				background-color: ${theme.colors.accent};
				border-color: ${theme.colors.textOnlyAccent};
				color: ${theme.colors.textOverAccent};

				@media (hover) {
					&:hover {
						background-color: ${theme.colors.accentState};
						border-color: ${theme.colors.accentState};
						color:  ${theme.colors.textOverAccent};
					}
				}`)
			: ($rule = `${$rule}
				background-color: ${lighten(0.15, desaturate(1, theme.colors.accent))};
				border-color: ${lighten(0.15, desaturate(1, theme.colors.textOnlyAccent))};
				color: ${lighten(0.15, desaturate(1, theme.colors.textOverAccent))};
			`);
	}
	// #region Icontool
	if (type === 'icontool') {
		//normal
		style === 'normal'
			? !disabled
				? selected
					? ($rule = `
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.primary};
						`)
					: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${theme.colors.textMain};
			
					@media (hover) {
						&:hover {
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.primary};
						}
					}`)
				: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${theme.colors.iconDisabled};
				`)
			: //outline
			!disabled
			? ($rule = `${$rule}
				background-color: transparent;
				border-color: ${theme.colors.textMain};
				color: ${theme.colors.textMain};

				@media (hover) {
					&:hover {
						background-color: transparent;
						border-color: ${theme.colors.primary};
						color: ${theme.colors.primary};
					}
				}`)
			: ($rule = `${$rule}
				background-color: transparent;
				border-color: ${theme.colors.iconDisabled};
				color: ${theme.colors.iconDisabled};
			`);
	}
	// #region Text
	if (type === 'text') {
		//normal
		style === 'normal'
			? !disabled
				? selected
					? ($rule = `
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.accentState};
						`)
					: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${theme.colors.textOnlyAccent};
			
					@media (hover) {
						&:hover {
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.accentState};
						}
					}`)
				: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${lighten(0.15, desaturate(1, theme.colors.textOnlyAccent))};
				`)
			: //outline
			!disabled
			? ($rule = `${$rule}
				background-color: transparent;
				border-color: ${theme.colors.textOnlyAccent};
				color: ${theme.colors.textOnlyAccent};

				@media (hover) {
					&:hover {
						background-color: transparent;
						border-color: ${theme.colors.accentState};
						color: ${theme.colors.accentState};
					}
				}`)
			: ($rule = `${$rule}
				background-color: transparent;
				border-color: ${lighten(0.15, desaturate(1, theme.colors.textOnlyAccent))};
				color: ${lighten(0.15, desaturate(1, theme.colors.textOnlyAccent))};
			`);
	}
	// #region Alternative
	if (type === 'alternative') {
		//normal
		style === 'normal'
			? !disabled
				? selected
					? ($rule = `
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.primaryState};
						`)
					: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${theme.colors.primary};
			
					@media (hover) {
						&:hover {
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.primaryState};
						}
					}`)
				: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${lighten(0.15, desaturate(1, theme.colors.primary))};
				`)
			: //outline
			!disabled
			? ($rule = `${$rule}
				background-color: transparent;
				border-color: ${theme.colors.primary};
				color: ${theme.colors.primary};

				@media (hover) {
					&:hover {
						background-color: transparent;
						border-color: ${theme.colors.primaryState};
						color: ${theme.colors.primaryState};
					}
				}`)
			: ($rule = `${$rule}
				background-color: transparent;
				border-color: ${lighten(0.15, desaturate(1, theme.colors.primary))};
				color: ${lighten(0.15, desaturate(1, theme.colors.primary))};
			`);
	}
	// #region Toggle
	if (type === 'toggle') {
		//normal
		style === 'normal'
			? !disabled
				? ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: ${theme.colors.accent};
			
					@media (hover) {
						&:hover {
							background-color: transparent;
							border-color: transparent;
							color: ${theme.colors.accentState};
						}
					}`)
				: ($rule = `${$rule}
					background-color: transparent;
					border-color: transparent;
					color: transparent;
				`)
			: //outline
			!disabled
			? ($rule = `${$rule}
				background-color: transparent;
				border-color: transparent;
				color: ${theme.colors.accent};

				@media (hover) {
					&:hover {
						background-color: transparent;
						border-color: transparent;
						color: ${theme.colors.accentState};
					}
				}`)
			: ($rule = `${$rule}
				background-color: transparent;
				border-color: ${lighten(0.15, desaturate(1, theme.colors.accent))};
					color: ${lighten(0.15, desaturate(1, theme.colors.textOverAccent))};
			`);
	}
	// Basic hover
	if (!disabled) {
		$rule = `${$rule}
			@media (hover) {
				&:hover {
					cursor: pointer;
				}
			}
			`;
	}

	return $rule;
};

const ButtonContainer = styled.button<ButtonContainerProps>`
	font-size: 14px;
	border-width: 1px;
	border-style: solid;
	border-radius: ${(props) =>
		props.$smallRadius
			? `calc(${props.theme.measures.borderRadius} * 2)`
			: `calc(${props.theme.measures.borderRadius} * 6)`};
	padding: ${(props) => (!props.$small ? '10px 14px' : '10px 14px')};
	display: flex;
	align-items: center;
	justify-content: center;

	${Icon} {
		width: 16px;
		height: 16px;
		transform: scale(1.25);
	}

	${Icon} + * {
		margin-left: 8px;
	}

	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: ${(props) => (!props.$small ? '10px 12px' : '6px 10px')};
		font-size: 12px;
	}
`;

export const _Button: FC<React.PropsWithRef<React.HTMLProps<HTMLButtonElement>> & ButtonProps> = ({
	smallRadius,
	outline,
	small,
	icon,
	children,
	...rest
}) => {
	return (
		<ButtonContainer {...rest} type='button' as={'button'} $smallRadius={smallRadius} $small={small}>
			{icon && <Icon>{icon}</Icon>}
			{children && <span>{children}</span>}
		</ButtonContainer>
	);
};

export const Button = styled(_Button)`
	${(props) => applyStyle(props.theme, 'basic', props.outline ? 'outline' : 'normal', props.selected, props.disabled)}
`;

export const TextButton = styled(_Button)`
	${(props) => applyStyle(props.theme, 'text', 'normal', props.selected, props.disabled)}
`;

export const PrimaryButton = styled(_Button)`
	${(props) =>
		applyStyle(props.theme, 'primary', props.outline ? 'outline' : 'normal', props.selected, props.disabled)}
	font-weight: bold;
`;

export const FooterButton = styled(_Button)`
	${(props) => applyStyle(props.theme, 'text', 'normal', props.selected, props.disabled)}
	padding: 8px;
	font-weight: bold;
	border-radius: ${(props) => `calc( ${props.theme.measures.borderRadius} * 2)`};
	@media (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: ${(props) => (!props.small ? '12px' : '6px 10px')};
	}
`;

export const FooterAltButton = styled(_Button)`
	${(props) => applyStyle(props.theme, 'alternative', 'normal', props.selected, props.disabled)}
	padding: 8px;
	font-weight: bold;
	border-radius: ${(props) => `calc( ${props.theme.measures.borderRadius} * 2)`};
	@media (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: ${(props) => (!props.small ? '12px' : '6px 10px')};
	}
`;

export const FooterOutlineButton = styled(_Button)`
	${(props) => applyStyle(props.theme, 'text', 'outline', props.selected, props.disabled)}
	font-weight: bold;
	@media (max-width: ${MOBILE_BREAKPOINT_PX}) {
		padding: ${(props) => (!props.small ? '12px' : '6px 10px')};
	}
`;

export const RemoveButton = styled(_Button)`
	${(props) => applyStyle(props.theme, 'text', 'normal', props.selected, props.disabled)}
`;

export const ToggleButton = styled(_Button)`
	padding: 0;
	margin: 0;
	${(props) => applyStyle(props.theme, 'toggle', 'normal', props.selected, props.disabled)}
`;

export const IconButton = styled(_Button)`
	${(props) => applyStyle(props.theme, 'icontool', 'normal', props.selected, props.disabled)}
`;

const ConfirmButtonContainer = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	padding: 0;
	border: 0 none;
	background-color: ${(props) => props.theme.colors.accent};
	color: ${(props) => props.theme.colors.textOverAccent};
	font-size: 14px;
	font-weight: 500;
	cursor: pointer;
	border-radius: 50%;

	&[disabled] {
		background-color: ${(props) => lighten(0.15, desaturate(1, props.theme.colors.accent))};
		color: ${(props) => lighten(0.15, desaturate(1, props.theme.colors.textOverAccent))};
	}
`;

export const ConfirmButton: FC<React.PropsWithRef<ComponentProps<typeof ConfirmButtonContainer>>> = (props) => {
	return (
		<ConfirmButtonContainer {...props}>
			<Icon>
				<DoneIcon />
			</Icon>
		</ConfirmButtonContainer>
	);
};

export const ButtonGroupStack = styled(Stack)<{ $space?: 'flex-end' | 'flex-start' | 'space-between' }>`
	display: flex;
	overflow: hidden;
	justify-content: ${(props) => (props.$space ? props.$space : 'space-between')};
	width: 100%;
	padding-inline: 5px;

	${IconButton} {
		margin: 0;
		padding: 0;
	}
`;
