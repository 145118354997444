import { useEffect, useRef } from "react";

const useEffectOnceWhen = (callback: () => void, when = true): void => {
	const hasRunOnceRef = useRef(false);
	const callbackRef = useRef(callback);
	useEffect(() => {
		callbackRef.current = callback;
	});
	useEffect(() => {
		if (when && !hasRunOnceRef.current) {
			callbackRef.current();
			hasRunOnceRef.current = true;
		}
	}, [when]);
};

export default useEffectOnceWhen;