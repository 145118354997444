import { MessageDialog } from '../dialogs';
import { useZakekeTranslations } from '@zakeke/zakeke-customizer-react';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '../../globals';
import { ReactComponent as ErrorIcon } from '../../../shared/icons/warning-solid.svg';

const ErrorIconWrap = styled(Icon)`
	display: inline-block;
	vertical-align: text-bottom;
	margin-inline-end: 5px;
	width: 18px;
	height: 18px;

	svg {
		fill: ${(props) => props.theme.colors.error};
	}
`;

const MessageWrapper = styled.div`
	text-align: left;
	width: 100%;
	padding-block: 4px;
`;

export type ErrorDialogProps = {
	title?: string;
	message: string;
	showButtons?: boolean;
	onClose?: () => void;
};
const ErrorDialog: React.FC<ErrorDialogProps> = ({ title, message, showButtons = true, onClose }) => {
	const { T } = useZakekeTranslations();
	return (
		<MessageDialog
			title={title ?? T._('Error')}
			message={
				<MessageWrapper>
					<ErrorIconWrap>
						<ErrorIcon />
					</ErrorIconWrap>
					{message}
				</MessageWrapper>
			}
			onClose={onClose}
			showButtons={showButtons}
		/>
	);
};

export default ErrorDialog;
