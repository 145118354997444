import React, { createContext, FC, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { css, styled } from 'styled-components';
import { ReactComponent as TimesIcon } from '../../../shared/icons/times-solid.svg';

import { dropdownPortal } from '../../../shared/portals';
import { useAppStore } from '../../../state/store';
import { Icon } from '../../globals';

export const dropdownContext = createContext({ dropdownId: '' });

export function useDropdownManager() {
	const [showDropdown, removeDropdown] = useAppStore((x) => [x.showDropdown, x.removeDropdown]);
	const { dropdownId } = useContext(dropdownContext);

	return {
		currentDropdownId: dropdownId,
		showDropdown: showDropdown,
		closeDropdown: removeDropdown,
	};
}

const DropdownBackdrop = styled.div`
	position: fixed;
	z-index: 900;
	inset: 0;
`;
const DropdownContainer = styled.div<{ x?: number; y?: number; $transparentBg?: boolean }>`
	position: fixed;
	z-index: 900;
	left: ${(props) => (props.x ? props.x : 0)}px;
	top: ${(props) => (props.y ? props.y : 0)}px;
	height: auto;
	background-color: ${(props) => (props.$transparentBg ? 'transparent' : props.theme.colors.backgroundMain)};
	border: ${(props) => (props.$transparentBg ? '0 none' : `1px solid ${props.theme.colors.borders}`)};
	border-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.15);
`;

export const DropdownRenderer: FC<{}> = () => {
	const [dropdowns] = useAppStore((x) => [x.dropdowns]);
	return (
		<>
			{createPortal(
				dropdowns.map(([id, dropdown]) => (
					<dropdownContext.Provider key={id} value={{ dropdownId: id }}>
						{dropdown}
					</dropdownContext.Provider>
				)),
				dropdownPortal,
			)}
		</>
	);
};

type Anchor = 'left' | 'top' | 'bottom' | 'right';

type Alignment = 'left' | 'top' | 'bottom' | 'right';

const DropdownWrapper: FC<{
	el: HTMLElement;
	anchor: Anchor;
	alignment: Alignment;
	transparentBg?: boolean;
	children?: React.ReactNode;
	verticalCorrection?: number;
	horizontalCorrection?: number;
}> = ({ el, anchor, alignment, transparentBg, children, verticalCorrection = 0, horizontalCorrection = 0 }) => {
	const dropdownRef = useRef<HTMLDivElement | null>(null);
	const [x, setX] = useState(0);
	const [y, setY] = useState(0);

	const getPosition = (anchor: Anchor, alignment: Alignment) => {
		if (!dropdownRef.current) return [0, 0];

		const dropdownWidth = dropdownRef.current.getBoundingClientRect().width;
		const dropdownHeight = dropdownRef.current.getBoundingClientRect().height;

		let buttonX = el.getBoundingClientRect().x;
		let buttonY = el.getBoundingClientRect().y;
		let buttonWidth = el.getBoundingClientRect().width;
		let buttonHeight = el.getBoundingClientRect().height;

		let dropdownPositionX = buttonX + horizontalCorrection;
		let dropdownPositionY = buttonY + verticalCorrection;

		switch (anchor) {
			case 'top':
				dropdownPositionY = buttonY - dropdownHeight + verticalCorrection;

				if (alignment === 'right') {
					dropdownPositionX = buttonX + buttonWidth - dropdownWidth + horizontalCorrection;
				}
				break;
			case 'bottom':
				dropdownPositionY = buttonY + buttonHeight + verticalCorrection;

				if (alignment === 'right') {
					dropdownPositionX = buttonX + buttonWidth - dropdownWidth + horizontalCorrection;
				}
				break;
			case 'left':
				dropdownPositionX = buttonX - dropdownWidth + horizontalCorrection;

				if (alignment === 'bottom') {
					dropdownPositionY = buttonY + buttonHeight - dropdownHeight - verticalCorrection;
				}
				break;
			case 'right':
				dropdownPositionX = buttonX + buttonWidth + horizontalCorrection;

				if (alignment === 'bottom') {
					dropdownPositionY = buttonY + buttonHeight - dropdownHeight - verticalCorrection;
				}
				break;
		}

		const dropdownWindowX = dropdownPositionX;
		const dropdownWindowY = dropdownPositionY;

		return [dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY];
	};

	useLayoutEffect(() => {
		if (!dropdownRef.current) return;

		const dropdownWidth = dropdownRef.current.getBoundingClientRect().width;
		const dropdownHeight = dropdownRef.current.getBoundingClientRect().height;

		let currentAnchor = anchor;
		let currentAlignment = alignment;

		let [dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
			currentAnchor,
			currentAlignment,
		);

		if (dropdownWindowX + dropdownWidth > window.innerWidth) {
			if (currentAnchor === 'right') {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					'left',
					currentAlignment,
				);
				currentAnchor = 'left';
			} else {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					currentAnchor,
					'right',
				);
				currentAlignment = 'right';
			}
		} else if (dropdownWindowX < 0) {
			if (currentAnchor === 'left') {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					'right',
					currentAlignment,
				);
				currentAnchor = 'right';
			} else {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					currentAnchor,
					'left',
				);
				currentAlignment = 'left';
			}
		}

		if (dropdownWindowY + dropdownHeight > window.innerHeight) {
			if (currentAnchor === 'bottom') {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					'top',
					currentAlignment,
				);
				currentAnchor = 'top';
			} else {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					currentAnchor,
					'bottom',
				);
				currentAlignment = 'bottom';
			}
		} else if (dropdownWindowY < 0) {
			if (currentAnchor === 'top') {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					'bottom',
					currentAlignment,
				);
				currentAnchor = 'bottom';
			} else {
				[dropdownPositionX, dropdownPositionY, dropdownWindowX, dropdownWindowY] = getPosition(
					currentAnchor,
					'top',
				);
				currentAlignment = 'top';
			}
		}

		// still need to prevent dropdown to exit screen vertically?
		if (dropdownWindowY < 0 || dropdownWindowY + dropdownHeight > window.innerHeight) {
			if (dropdownWindowY < 0) {
				dropdownPositionY = 10;
			} else {
				dropdownPositionY = window.innerHeight - 15 - dropdownHeight;
			}
		}

		// still need to prevent dropdown to exit screen horizontally?
		if (dropdownWindowX < 0 || dropdownWindowX + dropdownWidth > window.innerWidth) {
			if (dropdownWindowX < 0) {
				dropdownPositionX = 15;
			} else {
				dropdownPositionX = window.innerWidth - 15 - dropdownWidth;
			}
		}

		setX(dropdownPositionX);
		setY(dropdownPositionY);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [el, anchor, alignment, dropdownRef.current, window.innerWidth]);

	return (
		<DropdownContainer ref={dropdownRef} x={x} y={y} $transparentBg={transparentBg}>
			{children}
		</DropdownContainer>
	);
};

const DropdownPanelContainer = styled.div<{ $opened: boolean }>`
	display: flex;
	flex-direction: column;
	justify-content: start;
	position: fixed;
	left: 15px;
	right: 15px;
	bottom: 0;
	min-height: 160px;
	max-height: 75vh;
	padding: 0;
	background-color: ${(props) => props.theme.colors.backgroundSecondary};
	border-top-left-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	border-top-right-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	border: 1px solid ${(props) => props.theme.colors.borders};
	border-bottom-width: 0;
	transition: all ease 0.3s;
	z-index: 901;
	transform: translateY(100%);

	${(props) =>
		props.$opened &&
		css`
			&:not(:empty) {
				transform: translateY(0);
				transition-duration: 0.25s;
				transition-delay: 0.05s;
				box-shadow: ${props.theme.panels.shadows};
			}
		`}
`;
const DropdownPanelHeader = styled.div`
	font: ${(props) => props.theme.fonts.large200bold};
	font-size: 16px;
	display: flex;
	align-items: center;
	position: relative;
	padding: 12px 16px;
`;

const DropdownPanelContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	min-height: 0;
	padding: 16px 0 4px;
	border-top-left-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	border-top-right-radius: calc(${(props) => props.theme.measures.borderRadius} * 6);
	background-color: ${(props) => props.theme.colors.backgroundMain};
`;

const CloseIcon = styled(Icon)`
	margin-inline-start: auto;
	width: 20px;
	height: 20px;
	cursor: pointer;

	@media (hover) {
		&:hover {
			color: ${(props) => props.theme.colors.primary};
		}
	}
`;
type DropdownPanelProps = {
	opened: boolean;
	title?: React.ReactNode;
	onClose: () => void;
	children: React.ReactNode;
};
const DropdownPanel: FC<DropdownPanelProps> = ({ opened, title, onClose, children }) => {
	return (
		<>
			<DropdownPanelContainer $opened={opened}>
				{opened && title && (
					<DropdownPanelHeader>
						{title}
						<CloseIcon onClick={onClose}>
							<TimesIcon />
						</CloseIcon>
					</DropdownPanelHeader>
				)}
				<DropdownPanelContent>{opened && children}</DropdownPanelContent>
			</DropdownPanelContainer>
		</>
	);
};

export const DropdownPortal: FC<{
	el: HTMLElement;
	anchor?: Anchor;
	alignment?: Alignment;
	transparentBg?: boolean;
	children?: React.ReactNode;
	panel?: boolean;
	panelTitle?: React.ReactNode;
	onClose?: () => void;
	verticalCorrection?: number;
	horizontalCorrection?: number;
}> = ({
	el,
	anchor = 'top',
	alignment = 'left',
	transparentBg,
	children,
	panel = false,
	panelTitle,
	onClose,
	verticalCorrection,
	horizontalCorrection,
}) => {
	const { closeDropdown, currentDropdownId } = useDropdownManager();
	const [internalOpened, setInternalOpened] = useState(false);

	useEffect(() => {
		const timeout = panel ? 150 : 0;
		setTimeout(() => {
			setInternalOpened(true);
		}, timeout);
	}, [panel]);
	return (
		<>
			<DropdownBackdrop
				onMouseDown={() => {
					setInternalOpened(false);
					const timeout = panel ? 400 : 0;
					setTimeout(() => {
						onClose?.();
						closeDropdown(currentDropdownId);
					}, timeout);
				}}
			></DropdownBackdrop>
			{panel && (
				<DropdownPanel
					opened={internalOpened}
					title={panelTitle}
					onClose={() => {
						const timeout = panel ? 400 : 0;
						setInternalOpened(false);
						setTimeout(() => {
							onClose?.();
							closeDropdown(currentDropdownId);
						}, timeout);
					}}
				>
					{children}
				</DropdownPanel>
			)}
			{!panel && (
				<DropdownWrapper
					el={el}
					anchor={anchor}
					alignment={alignment}
					transparentBg={transparentBg}
					verticalCorrection={verticalCorrection}
					horizontalCorrection={horizontalCorrection}
				>
					{children}
				</DropdownWrapper>
			)}
		</>
	);
};
