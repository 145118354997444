// import original module declarations
import 'styled-components';
import { DefaultTheme } from 'styled-components';

/**
 * Old settings:
 * | key | text | icon | position | hide |
 * |---|---|---|---|---|
 * | clipart | yes | yes | yes |  no |
 * | upload | yes | yes | yes |  no |
 * | text | yes | yes | yes |  no |
 * | palette (product-colors) | yes | yes | yes |  no |
 * | printing-methods | yes | yes | yes |  yes |
 * | drafts (my-saved-design) | yes | yes | yes |  no |
 * | templates (pre-designed-templates) | yes | yes | yes |  no |
 * | sides | yes | yes | yes |  no |
 * | background | yes | yes | yes |  no |
 * | cart (add-to-cart) | no | yes | no | no |
 * | collages | yes | yes | yes |  yes |
 * | shapes | yes | yes | yes |  yes |
 * | edit-shape | no | yes | no | yes |
 * | edit-image | no | yes | no | yes |
 */

export type ToolIconKey =
	| 'design-elements'
	| 'text'
	| 'textart'
	| 'collages'
	| 'background'
	| 'drafts'
	| 'templates'
	| 'shapes'
	| 'upload-image'
	| 'cliparts'
	| 'cart';
// new configs
export type DesignElementsToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type TextToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type TextArtToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type ClipartToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type UploadImageToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type DraftsToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
};
export type TemplatesToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type BackgroundToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
};
export type CollagesToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
	visible: boolean;
};
export type ShapesToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
	position: number;
	visible: boolean;
};
export type PaletteToolConfig = {
	visible: boolean;
};
export type PrintingMethodsToolConfig = {
	visible: boolean;
};
export type CartToolConfig = {
	text?: string;
	defaultText: string;
	iconUrl?: string;
	defaultIconKey: ToolIconKey;
};

export type ToolsConfig = {
	designElements: DesignElementsToolConfig;
	uploadImage: UploadImageToolConfig;
	cliparts: ClipartToolConfig;
	text: TextToolConfig;
	textArt: TextArtToolConfig;
	background: BackgroundToolConfig;
	templates: TemplatesToolConfig;
	collages: CollagesToolConfig;
	shapes: ShapesToolConfig;
	palette: PaletteToolConfig;
	printingMethods: PrintingMethodsToolConfig;
	drafts: DraftsToolConfig;
	cart: CartToolConfig;
};

export type FontFamilyConfig = {
	name: string;
	styleUrl: string;
	allTextUppercase: boolean;
};
export type FontsConfig = {
	large100bold: string;
	large200bold: string;
	large300bold: string;
	large500bold: string;
	largeBody: string;
	large200: string;
	smallBodyBold: string;
};
export type ColorsConfig = {
	primary: string;
	primaryState: string;
	accent: string;
	accentState: string;
	textOverPrimary: string;
	textOverAccent: string;
	textOnlyAccent: string;
	textMain: string;
	textSecondary: string;
	textFooter: string;
	textDisabled: string;
	backgroundMain: string;
	backgroundSecondary: string;
	backgroundFooter: string;
	background3d: string;
	backgroundPreview: string;
	borders: string;
	iconMain: string;
	iconSecondary: string;
	iconSecondaryState: string;
	iconDisabled: string;
	error: string;
	errorText: string;
	errorLight: string;
	success: string;
	scrollAlternative: string;
};
export type SpacingConfig = {
	horizontalPadderNumber: number;
	horizontalPadderMobileNumber: number;
	horizontalPadder: string;
	horizontalPadderMobile: string;
};
export type MeasuresConfig = {
	borderRadius: string;
};
export type PanelsConfig = {
	borderWidth: string;
	shadows: string;
};
export type DialogsConfig = {
	backdrop: string;
	shadows: string;
};
export type PricingConfig = {
	showPrice: boolean;
	priceInfo?: string;
};
export type ZoomConfig = {
	zoomPercentageIncrement: number;
};
export type Preview3DConfig = {
	allowPreview3DExpandOnContainerClick: boolean;
	showResize3DPreview: boolean;
	showResize3DHeight: number;
	showResize3DWidth: number;
};
export type GeneralConfig = {
	measurementUnit: string;
	showSideDimensions: boolean;
	showElementsDimensions: boolean;
	showSelectedElementDimensions: boolean;
	showAreaDimensions: boolean;
	isUndoRedoEnabled: boolean;
};

export type Theme = {
	fontFamily: FontFamilyConfig;
	fonts: FontsConfig;
	colors: ColorsConfig;
	tools: ToolsConfig;
	spacing: SpacingConfig;
	measures: MeasuresConfig;
	panels: PanelsConfig;
	dialogs: DialogsConfig;
	pricing: PricingConfig;
	zoom: ZoomConfig;
	preview3d: Preview3DConfig;
	general: GeneralConfig;
};

export type ToolConfigKey =
	| 'design-elements'
	| 'upload-image'
	| 'cliparts'
	| 'text'
	| 'textart'
	| 'background'
	| 'templates'
	| 'collages'
	| 'shapes'
	| 'palette'
	| 'printing-methods'
	| 'drafts'
	| 'cart'
	| 'edit-shape'
	| 'edit-image';

// and extend them!
declare module 'styled-components' {
	export interface DefaultTheme extends Theme {}
}

const horizontalPadderNumber = 22;
const horizontalPadderMobileNumber = 20;

export const createThemeFont = (fontFamily: string): DefaultTheme['fonts'] => {
	return {
		large100bold: `normal normal 700 14px/20px ${fontFamily}`,
		large200bold: `normal normal 700 16px/24px ${fontFamily}`,
		large300bold: `normal normal 700 20px/28px ${fontFamily}`,
		large500bold: `normal normal 700 32px/40px ${fontFamily}`,
		largeBody: `normal normal 400 14px/20px ${fontFamily}`,
		large200: `normal normal 400 16px/24px ${fontFamily}`,
		smallBodyBold: `normal normal 700 15px/20px ${fontFamily}`,
	};
};

export const defaultTheme: DefaultTheme = {
	fontFamily: {
		name: 'Inter',
		styleUrl: `${process.env.PUBLIC_URL}/assets/fonts/inter/style.css`,
		allTextUppercase: false,
	},
	fonts: createThemeFont('Inter'),
	colors: {
		primary: '#0073D3',
		primaryState: '#128DF3',
		accent: '#F46200',
		accentState: '#F79F64',

		textOverPrimary: '#FFFFFF',
		textOverAccent: '#FFFFFF',
		textOnlyAccent: '#E65C00',
		textMain: '#000000',
		textSecondary: '#000000',
		textFooter: '#000000',
		textDisabled: '#8FA4AE',

		backgroundMain: '#FFFFFF',
		backgroundSecondary: '#F1F2F3',
		backgroundFooter: '#FFFFFF',
		background3d: '#F1F2F3',
		backgroundPreview: '#DFDFE0',

		borders: '#DBE2E6',

		iconMain: '#1A1C1D',
		iconSecondary: '#1A1C1D',
		iconSecondaryState: '#0073D3',
		iconDisabled: '#BABEC3',

		error: '#DE1C22',
		errorText: '#9F1B1F',
		errorLight: '#FFF4F4',
		success: '#086343',

		scrollAlternative: '#8fa4ae',
	},
	tools: {
		designElements: {
			defaultText: 'Design',
			defaultIconKey: 'design-elements',
			position: 0,
		},
		uploadImage: {
			defaultText: 'Upload',
			defaultIconKey: 'upload-image',
			position: 1,
		},
		cliparts: {
			defaultText: 'Cliparts',
			defaultIconKey: 'cliparts',
			position: 2,
		},
		text: {
			defaultText: 'Text',
			defaultIconKey: 'text',
			position: 3,
		},
		textArt: {
			defaultText: 'Text Art',
			defaultIconKey: 'textart',
			position: 4,
		},
		background: {
			defaultText: 'background',
			defaultIconKey: 'background',
			position: 5,
		},
		templates: {
			defaultText: 'Templates',
			defaultIconKey: 'templates',
			position: 6,
		},
		collages: {
			defaultText: 'Collages',
			defaultIconKey: 'collages',
			position: 7,
			visible: true,
		},
		shapes: {
			defaultText: 'Shapes',
			defaultIconKey: 'shapes',
			position: 8,
			visible: true,
		},
		palette: {
			visible: true,
		},
		printingMethods: {
			visible: true,
		},
		drafts: {
			defaultText: 'My saved designs',
			defaultIconKey: 'drafts',
		},
		cart: {
			defaultText: 'Add to cart',
			defaultIconKey: 'cart',
		},
	},
	spacing: {
		horizontalPadderNumber,
		horizontalPadderMobileNumber,
		horizontalPadder: `${horizontalPadderNumber}px`,
		horizontalPadderMobile: `${horizontalPadderMobileNumber}px`,
	},
	measures: {
		borderRadius: '4px',
	},
	panels: {
		borderWidth: '1px 1px 5px 1px',
		shadows: '0 0 1px rgba(0, 0, 0, 0.25), 0 2px 1px rgba(0, 0, 0, 0.05)',
	},
	dialogs: {
		backdrop: 'rgba(0, 0, 0, 0.3)',
		shadows: '0 4px 20px rgba(0, 0, 0, 0.15), 0 0 3px rgba(0, 0, 0, 0.1)',
	},
	pricing: {
		showPrice: true,
	},
	zoom: {
		zoomPercentageIncrement: 100,
	},
	preview3d: {
		allowPreview3DExpandOnContainerClick: true,
		showResize3DPreview: true,
		showResize3DHeight: 200,
		showResize3DWidth: 220,
	},
	general: {
		measurementUnit: 'cm',
		showSideDimensions: true,
		showElementsDimensions: true,
		showSelectedElementDimensions: false,
		showAreaDimensions: false,
		isUndoRedoEnabled: true,
	},
};

type ThemeOverrides = {
	fontFamily?: Partial<DefaultTheme['fontFamily']>;
	fonts?: Partial<DefaultTheme['fonts']>;
	colors?: Partial<DefaultTheme['colors']>;
	tools?: Partial<DefaultTheme['tools']>;
	spacing?: Partial<DefaultTheme['spacing']>;
	measures?: Partial<DefaultTheme['measures']>;
	panels?: Partial<DefaultTheme['panels']>;
	dialogs?: Partial<DefaultTheme['dialogs']>;
	pricing?: Partial<DefaultTheme['pricing']>;
	zoom?: Partial<DefaultTheme['zoom']>;
	preview3d?: Partial<DefaultTheme['preview3d']>;
	general?: Partial<DefaultTheme['general']>;
};
export const createTheme = (overrides: ThemeOverrides): DefaultTheme => {
	return {
		...defaultTheme,
		fontFamily: {
			...defaultTheme.fontFamily,
			...overrides.fontFamily,
		},
		fonts: {
			...defaultTheme.fonts,
			...overrides.fonts,
		},
		colors: {
			...defaultTheme.colors,
			...overrides.colors,
		},
		tools: {
			...defaultTheme.tools,
			...overrides.tools,
		},
		spacing: {
			...defaultTheme.spacing,
			...overrides.spacing,
		},
		measures: {
			...defaultTheme.measures,
			...overrides.measures,
		},
		panels: {
			...defaultTheme.panels,
			...overrides.panels,
		},
		dialogs: {
			...defaultTheme.dialogs,
			...overrides.dialogs,
		},
		pricing: {
			...defaultTheme.pricing,
			...overrides.pricing,
		},
		zoom: {
			...defaultTheme.zoom,
			...overrides.zoom,
		},
		preview3d: {
			...defaultTheme.preview3d,
			...overrides.preview3d,
		},
		general: {
			...defaultTheme.general,
			...overrides.general,
		},
	};
};
