import { FC } from 'react';
import styled from 'styled-components';

const InternalSpinner: FC<{ className?: string }> = ({ className }) => (
	<svg viewBox='0 0 50 50' className={className}>
		<circle className='path' cx='25' cy='25' r='20' fill='none' strokeWidth='2' />
	</svg>
);

const RotatingSpinner = styled(InternalSpinner)`
	animation: rotate 1s linear infinite;
	width: 54px;
	height: 54px;

	& .path {
		stroke: ${(props) => props.theme.colors.primary};
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}

	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}

	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

// This is used to avoid the parent of the spinner to scroll because of the rotation
const SpinnerNoOverlowContainer = styled.div`
	width: 54px;
	height: 54px;
	overflow: hidden;
`;

const SpinnerNoOverlow: FC<{ className?: string }> = ({ className }) => {
	return (
		<SpinnerNoOverlowContainer className={className}>
			<RotatingSpinner />
		</SpinnerNoOverlowContainer>
	);
};

const Spinner = styled(SpinnerNoOverlow)``;
export default Spinner;
