import { ItemConstraints } from '@zakeke/zakeke-customizer-react';
import { ImageElement } from '../components/interfaces';
import { checkConstraints } from '../state/utils';

/**
 * check if image has constrains or if it has preferredHeight and preferredWidth
 * @param image
 * @param isClipartsResizeEnabled
 * @returns
 */
export const checkImageResizable: <
	T extends {
		preferredHeight?: ImageElement['preferredHeight'];
		preferredWidth?: ImageElement['preferredWidth'];
		constraints?: undefined | null | ItemConstraints;
	},
>(
	image: T,
	isClipartsResizeEnabled: boolean,
) => boolean = (image, isClipartsResizeEnabled) => {
	const clipCanResize = !(!!image.preferredHeight && !!image.preferredWidth && !isClipartsResizeEnabled);
	return clipCanResize && checkConstraints(image, 'canResize') !== false;
};
