import { useZakekeProduct } from '@zakeke/zakeke-customizer-react';
import { useMemo } from 'react';
import { useAppStore } from '../state/store';

const useSelectedVariant = () => {
    const product = useZakekeProduct();
    const selectedVariantId = useAppStore(x => x.selectedVariantId);
    return useMemo(() => product?.variants.find(x => x.id === selectedVariantId), [selectedVariantId, product]);
}

export default useSelectedVariant;