/*
	utilità di calcolo legate alle martici
 */

import { ZakekeMatrix } from '@zakeke/zakeke-customizer-react';

// #region types
// type item = { guid: string; width: number; height: number, matrix?: ZakekeMatrix|null };
// #endregion

// #region utility

// #endregion

// #region export
const setMatrixResize = (
	matrix: ZakekeMatrix | null,
	scalex: number,
	scaley: number,
	center?: { x: number; y: number },
) => {
	if (!matrix) return null;
	return matrix.prepended(
		new ZakekeMatrix().scale(scalex, scaley, center?.x ?? matrix.translation.x, center?.y ?? matrix.translation.y),
	);
};
export const setMatrixLinearResize = (
	matrix: ZakekeMatrix | null,
	scale: number,
	center?: { x: number; y: number },
) => {
	return setMatrixResize(matrix, scale, scale, center);
};

export const setMatrixRotation = (
	matrix: ZakekeMatrix | null,
	rotation: number,
	center?: { x: number; y: number } | null,
) => {
	if (!matrix) return null;
	const normalizedRotation = normalizeMatrixRotation(matrix);

	return matrix.prepended(
		new ZakekeMatrix().rotate(
			rotation - (normalizedRotation ?? 0),
			center?.x ?? matrix.translation.x,
			center?.y ?? matrix.translation.y,
		),
	);
};

export const normalizeMatrixRotation = (matrix: ZakekeMatrix | null) => {
	if (!matrix) return null;
	let result = 0;
	// 360 + matrix?.rotation: perché - con - fa +
	if (matrix.rotation < 0) result = 360 + (matrix.rotation % 360);
	else if (matrix.rotation > 360) result = matrix.rotation % 360;
	else result = matrix.rotation;

	return result;
};
// #endregion
