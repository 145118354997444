/* eslint-disable no-loop-func */
import React, { useEffect, useState } from 'react';
import update from 'immutability-helper';
import { camelCase, startCase, uniqBy } from 'lodash';
import { darken } from 'polished';
import styled from 'styled-components';
import {
	ProductVariant,
	createElementDescsGuid,
	createModificationGuid,
	useZakekeAddToCart,
	useZakekeBulkAndNameNumber,
	useZakekeStoreProductVariants,
	useZakekeTranslations,
} from '@zakeke/zakeke-customizer-react';

import useSelectedVariant from '../../hooks/useSelectedVariant';
import { MOBILE_BREAKPOINT_PX, isMobile, stringFormat } from '../../shared/helpers';
import { ReactComponent as TrashIcon } from '../../shared/icons/delete.svg';
import { DesignItemTagType } from '../../state/interfaces/designItemTagType';
import { useAppStore, useItemsValue } from '../../state/store';
import AdvancedSelect from '../widgets/advancedSelect';
import { RemoveButton, TextButton } from '../widgets/button';
import { Dialog, DialogWindow, DialogFooter, useDialogManager } from '../widgets/dialogs';
import { CustomizerElement } from '../interfaces';
import { FormControl, Icon, Input, InputStyle } from '../globals';

const CustomWindow = styled(DialogWindow)`
	max-width: 1200px;
	min-height: 400px;
	padding: 25px 0;
	font-size: 14px;

	${DialogFooter} {
		padding-left: 50px;
		padding-right: 50px;
	}

	${TextButton} {
		margin-top: 20px;
		margin-left: auto;
		margin-right: auto;
	}
`;

const GridRow = styled.div`
	border-bottom: 1px solid ${(props) => props.theme.colors.borders};
	&:nth-child(2n + 3) {
		background-color: ${(props) => darken(0.015, props.theme.colors.backgroundMain)};
	}
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		&:first-child {
			border-top: 1px solid ${(props) => props.theme.colors.borders};
		}
		&:nth-child(even) {
			background-color: ${(props) => darken(0.015, props.theme.colors.backgroundMain)};
		}
		&:nth-child(odd) {
			background-color: transparent;
		}
	}
`;

const Grid = styled.div<{ $tagColumns: number; $attributeColumns: number }>`
	${GridRow} {
		min-height: 80px;
		display: grid;
		gap: 16px;
		grid-template-columns: 50px ${(props) =>
				props.$tagColumns > 0 && `repeat(${props.$tagColumns}, minmax(120px, 1fr))`} ${(props) =>
				props.$attributeColumns > 0 &&
				`repeat(${props.$attributeColumns}, minmax(100px, 1fr))`} 110px 70px 100px 75px;

		&:first-child {
			min-height: 48px;
			margin-top: 12px;
		}

		@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
			grid-template-columns: 1fr;
			padding: 24px 25px 24px;
		}
	}
`;

const Cell = styled.div<{ $invalid?: boolean }>`
	display: flex;
	align-items: center;

	&:first-child {
		padding-left: 25px;
		font-weight: bold;
	}
	&:last-child {
		padding-right: 25px;
	}
	@media screen and (max-width: ${MOBILE_BREAKPOINT_PX}) {
		&:first-child {
			padding-left: 0;
		}
		&:last-child {
			padding-right: 0;
		}
		label {
			font-weight: bold;
		}
	}

	> * {
		flex: 1;
	}
	input {
		${InputStyle};
		min-height: 0;
		max-height: 40px;

		&:active,
		&:focus {
			border-color: ${(props) => props.theme.colors.primary};
		}
	}
`;

const HeaderCell = styled(Cell)`
	font-weight: bold;
	padding: 10px;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;

	&:first-child {
		grid-column-start: 1;
	}
`;

const NumberCell = styled(Cell)`
	grid-column-start: 1;
	flex: 1;
`;

interface ItemRow {
	descGuid: string;
	modificationGuid: string;
	values: Map<string, string>;
	hasError: Map<string, boolean>;
	attributes: Map<string, string | null | undefined>;
	quantity: string;
}

const TotalPriceInput = styled(Input)`
	font-weight: bold;
`;

const FooterContent = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
`;

const FooterContentError = styled.span`
	color: ${(props) => props.theme.colors.error};
`;

const OutOfStock = styled.span`
	color: ${(props) => props.theme.colors.error};
	font-size: 10px;
	display: inline-block;
	margin-left: 5px;
`;

const createRow = (
	items: CustomizerElement[],
	attributes: StoreAttribute[],
	initialSelectedVariant?: ProductVariant,
	initialQty?: number,
): ItemRow => {
	return {
		modificationGuid: createModificationGuid(),
		descGuid: createElementDescsGuid(),
		quantity: initialQty ? initialQty.toString() : '1',
		values: new Map(items.map((x) => [x.tag!, ''])),
		hasError: new Map(items.map((x) => [x.tag!, false])),
		attributes: new Map(
			attributes.map((x) => {
				if (initialSelectedVariant) {
					const initialValue = initialSelectedVariant.variantCode.find((variant) => variant.Id === x.id)
						?.Value.Id;
					if (initialValue) return [x.id, initialValue];
				}
				return [x.id, ''];
			}),
		),
	};
};

interface StoreAttribute {
	id: string;
	label: string;
	values: {
		id: string;
		label: string;
	}[];
}

interface StoreVariant {
	Id: string;
	Value: {
		Id: string;
	};
}

interface ProductPrice {
	isOutOfStock: boolean;
	finalPrice: number;
}

const NameNumberDialog = () => {
	const { currentDialogId, closeDialog } = useDialogManager();
	const [priceFormatter, editDesignId, nameNumberSelection, currentTemplate] = useAppStore((x) => [
		x.priceFormatter,
		x.editDesignId,
		x.nameNumberSelection,
		x.currentTemplate,
	]);
	const { addToCart, addToCartNameNumber } = useZakekeAddToCart();
	const { isSkipOptionForNameAndNumber, attributeCodes, getPrices, initialQty } = useZakekeBulkAndNameNumber();
	const getStoreVariants = useZakekeStoreProductVariants();
	const selectedVariant = useSelectedVariant();

	const items = useItemsValue();
	const validItems = items.filter((x) => x.tag && x.tagType === DesignItemTagType.TeamWear);

	const [prices, setPrices] = useState<(ProductPrice | null)[]>([]);
	const [unitPrices, setUnitPrices] = useState<(number | null)[]>([]);
	const [showErrors, setShowErrors] = useState(false);
	const [itemRows, setItemRows] = useState<ItemRow[]>([]);
	const [tags, setTags] = useState<string[]>([]);
	const [storeVariants, setStoreVariants] = useState<{ attributes: StoreAttribute[]; variants: StoreVariant[][] }>({
		attributes: [],
		variants: [],
	});

	const { T } = useZakekeTranslations();

	// Get attributes and transform to select options
	const filteredStoreAttributes = storeVariants.attributes.filter((x) => attributeCodes.includes(x.id));

	const storeValidAttributes = filteredStoreAttributes.map((attr) => ({
		...attr,
		values: attr.values.map((option) => ({
			value: option.id,
			label: option.label,
		})),
	}));

	const validateErrors = () => {
		let hasError = false;

		for (const index in itemRows) {
			const row = itemRows[index];

			for (const [tag, value] of row.values.entries()) {
				const item = validItems.find((x) => x.tag === tag)!;
				const minChars = item?.constraints?.minNrChars;
				const maxChars = item?.constraints?.maxNrChars;

				const invalidMin = minChars && minChars > 0 && value.length < minChars ? minChars : null;
				const invalidMax = maxChars && maxChars > 0 && value.length > maxChars ? maxChars : null;

				setItemRows((prev) =>
					update(prev, {
						[index]: {
							hasError: {
								[tag]: {
									$set: invalidMin !== null || invalidMax !== null,
								},
							},
						},
					}),
				);

				if (invalidMin !== null || invalidMax !== null) hasError = true;
			}
		}

		setShowErrors(hasError);
		return !hasError;
	};

	useEffect(() => {
		if (items && items.length > 0 && getStoreVariants) {
			getStoreVariants().then((x) => {
				const validAttributes = x.attributes.filter((x) => attributeCodes.includes(x.id));
				setStoreVariants(x);

				if (nameNumberSelection.length === 0) {
					setItemRows([createRow(validItems, validAttributes, selectedVariant, initialQty)]);
				} else {
					setItemRows(
						nameNumberSelection.map((x) => {
							return {
								modificationGuid: x.modificationGuid,
								descGuid: x.descGuid,
								quantity: x.quantity.toString(),
								values: x.values,
								hasError: new Map<string, boolean>(),
								attributes: x.attributes,
							};
						}),
					);
				}

				// Get unique tags
				setTags(uniqBy(validItems, (x) => x.tag).map((x) => x.tag!));
			});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items]);

	useEffect(() => {
		// Filter item values
		const selection = itemRows.map((x) => {
			return {
				attributes: x.attributes,
				quantity: parseInt(x.quantity),
			};
		});

		getPrices(selection).then((prices) => {
			const unitPrices = prices.map((x, id) => {
				if (x && parseInt(itemRows[id].quantity) > 0) return x.finalPrice / parseInt(itemRows[id].quantity);
				else return 0;
			});
			setPrices(prices);
			setUnitPrices(unitPrices);
		});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemRows]);

	const atLeastOnRowCompleted = prices.some((x) => x !== null && !x.isOutOfStock);

	const buttons = [
		{
			label: T._('Add to cart'),
			primary: true,
			outline: false,
			disabled: !atLeastOnRowCompleted,
			onClick: () => {
				if (validateErrors()) {
					closeDialog(currentDialogId);
					addToCartNameNumber({
						designId: editDesignId,
						templateId: currentTemplate?.templateId ?? null,
						nameNumberSelections: itemRows.map((x) => ({
							quantity: parseInt(x.quantity),
							values: x.values,
							modificationGuid: x.modificationGuid,
							descGuid: x.descGuid,
							attributes: x.attributes,
						})),
					});
				}
			},
		},
	];

	if (isSkipOptionForNameAndNumber) {
		buttons.unshift({
			label: T._('Skip'),
			primary: false,
			outline: true,
			disabled: false,
			onClick: () => {
				if (validateErrors()) {
					closeDialog(currentDialogId);
					addToCart({
						designId: editDesignId,
					});
				}
			},
		});
	}

	let firstError: { name: string; min: number | null | undefined; max: number | null | undefined } | null = null;
	for (const item of itemRows) {
		for (const [tag, error] of item.hasError) {
			if (error) {
				const item = items.find((x) => x.tag === tag)!;
				firstError = { name: item?.name, min: item.constraints?.minNrChars, max: item.constraints?.maxNrChars };
			}
		}
	}

	const footerContent = (
		<FooterContent>
			{showErrors && firstError && (
				<FooterContentError>
					{firstError.min !== null &&
						firstError.max !== null &&
						stringFormat(
							T._('The text {0} must respect the min of {1} chars and and max of {2} chars.'),
							firstError.name,
							firstError.min!.toString(),
							firstError.max!.toString(),
						)}
					{firstError.min !== null &&
						firstError.max === null &&
						stringFormat(
							T._('The text {0} must respect the min of {1} chars.'),
							firstError.name,
							firstError.min!.toString(),
						)}
					{firstError.min === null &&
						firstError.max !== null &&
						stringFormat(
							T._('The text {0} must respect the max of {1} chars.'),
							firstError.name,
							firstError.max!.toString(),
						)}
				</FooterContentError>
			)}
			<span>
				{T._('Total price')}:{' '}
				<strong>{priceFormatter.format(prices.reduce((acc, val) => acc + (val?.finalPrice ?? 0), 0))}</strong>
			</span>
		</FooterContent>
	);

	return (
		<Dialog
			windowDecorator={CustomWindow}
			title={T._('Name and numbers')}
			buttons={buttons}
			leftFooterCustomComponent={footerContent}
			alignTop={true}
			showCloseButton
		>
			<Grid $tagColumns={tags.length} $attributeColumns={storeValidAttributes.length}>
				{!isMobile() && (
					<GridRow>
						<HeaderCell>#</HeaderCell>
						{tags.map((tag) => {
							const item = validItems.find((x) => x.tag === tag)!;

							return <HeaderCell key={tag}>{item.name}</HeaderCell>;
						})}
						{storeValidAttributes.map((x) => (
							<HeaderCell key={x.id}>{startCase(camelCase(x.label))}</HeaderCell>
						))}
						<HeaderCell>{T._('Quantity')}</HeaderCell>
						<HeaderCell>{T._('Price')}</HeaderCell>
						<HeaderCell>{T._('Total')}</HeaderCell>
						<HeaderCell></HeaderCell>
					</GridRow>
				)}

				{itemRows.map((itemRow, idx) => {
					const isCompleted = Array.from(itemRow.attributes.values()).filter((x) => !x).length === 0;
					const isRowInvalid = isCompleted && (prices[idx] === null || prices[idx]?.isOutOfStock);

					return (
						<React.Fragment key={itemRow.modificationGuid}>
							<GridRow>
								{!isMobile() && (
									<NumberCell>
										<span>{idx + 1}</span>
									</NumberCell>
								)}

								{Array.from(itemRow.values.keys()).map((tag) => {
									const item = validItems.find((x) => x.tag === tag)!;

									return (
										<Cell key={`${itemRow.descGuid}_${tag}`}>
											<FormControl label={isMobile() ? item.name : ''}>
												<Input
													$invalid={itemRow.hasError.get(tag)}
													placeholder={item?.name}
													value={itemRow.values.get(tag)}
													onChange={(e) =>
														setItemRows((prev) => {
															return update(prev, {
																[idx]: {
																	values: {
																		[tag]: {
																			$set: e.target.value,
																		},
																	},
																},
															});
														})
													}
												/>
											</FormControl>
										</Cell>
									);
								})}

								{storeValidAttributes.map((attribute) => {
									return (
										<Cell key={`${itemRow.descGuid}_${attribute.id}`}>
											<FormControl label={isMobile() ? attribute.label : ''}>
												<AdvancedSelect<{ value: string; label: string }>
													menuPosition='fixed'
													isSearchable={false}
													options={attribute.values}
													value={attribute.values.find(
														(x) => x.value === itemRow.attributes.get(attribute.id),
													)}
													onChange={(item) =>
														setItemRows((prev) => {
															return update(prev, {
																[idx]: {
																	attributes: {
																		[attribute.id]: {
																			$set: item?.value,
																		},
																	},
																},
															});
														})
													}
												/>
											</FormControl>
										</Cell>
									);
								})}

								<Cell>
									<Input
										type='number'
										min={1}
										step={1}
										$invalid={isRowInvalid}
										value={itemRow.quantity}
										onChange={(e) =>
											setItemRows((prev) => {
												return update(prev, {
													[idx]: {
														quantity: {
															$set: e.target.value,
														},
													},
												});
											})
										}
									/>

									{isRowInvalid && <OutOfStock>{T._('Out of stock')}</OutOfStock>}
								</Cell>
								<Cell>
									<Input value={priceFormatter.format(unitPrices[idx] ?? 0)} step='0.01' readOnly />
								</Cell>
								<Cell>
									<TotalPriceInput
										value={priceFormatter.format(prices[idx]?.finalPrice ?? 0)}
										readOnly
									/>
								</Cell>
								<Cell>
									{itemRows.length > 1 && (
										<RemoveButton
											onClick={() => setItemRows(itemRows.filter((x) => x !== itemRow))}
										>
											<Icon>
												<TrashIcon />
											</Icon>
										</RemoveButton>
									)}
								</Cell>
							</GridRow>
						</React.Fragment>
					);
				})}
			</Grid>

			<TextButton onClick={(e) => setItemRows([...itemRows, createRow(validItems, filteredStoreAttributes)])}>
				<span>+ {T._('Add more')}</span>
			</TextButton>
		</Dialog>
	);
};

export default NameNumberDialog;
